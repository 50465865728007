import React from 'react';
import whiteCloseIcon from "../../assets/images/whiteCloseIcon.png";
import ArrowDownSVG from "../../assets/images/arrowDown.svg";
import DateRange from "../date-range/DateRange";
import {translation} from "../../helpers";

const Calendar = ({closeCalendar, dateTypeSelect, dateType, dateRange, setDateRange}) => {
    return (
        <div className='created-rows calendar-created-rows'>
            <div className='created-rows_item'>
                <button className='delete-row' type='button' onClick={closeCalendar}><img
                    src={whiteCloseIcon} alt="close-icon"/></button>
                <div className='created-rows_item--selects calendar-row'>
                    <div className='w-50'>
                        <div className='home_form-item'>
                            <div className='custom-select'>
                                <div className="user_container" onClick={dateTypeSelect}>
                                    <p className='dropdown-selected'>{translation('Publication Date')}</p>
                                    <img
                                        src={ArrowDownSVG}
                                        className={dateType ? 'select-close' : 'select-open'} alt='arrow-icon'
                                    />
                                </div>
                                <div className={dateType ? 'drop-down-section section-open' : 'drop-down-section'}>
                                    <p className='drop-down-section_button'>{translation('Publication Date')}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-50'>
                        <div className='home_form-item'>
                            <DateRange
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;