import React from 'react';
import {useSelector} from "react-redux";
import {dispatch} from "../../helpers";
import {globalSelector, setError} from "../../redux/slices/global/globalSlice";

const Error = () => {
    const {error} = useSelector(globalSelector);

    return (
        <div className="popup-background">
            <div className="popup-container">
                <div className="popup-header">
                    <h2>{error?.data?.error}.</h2>
                    <button className="popup-close" onClick={()=> dispatch(setError({}))}>X</button>
                </div>
                <div className="popup-content">
                    <p>{error?.data?.message[0]}</p>
                </div>
            </div>
        </div>
    );
};

export default Error;