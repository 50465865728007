import React from 'react';
import {handleChange} from "../../helpers";

const Authors = ({setInputsData, inputsData, handleChangeRows, index, value}) => {
    return (
        <>
            <div className='home_form-item'>
                <input
                    type='text'
                    name="authorId"
                    className='home_form-item_input'
                    placeholder='AuthorId or ORCID'
                    autoComplete="off"
                    value={inputsData ? inputsData.authorId : value.authorId}
                    maxLength={40}
                    onChange={inputsData ? (e) => handleChange(e, setInputsData, inputsData) : (e) => handleChangeRows(e, index)}
                />
            </div>
            <div className='home_form-item home_form_inline-items'>
                <input
                    type='text'
                    name="authorName"
                    className='home_form-item_input'
                    placeholder='First Name'
                    autoComplete="off"
                    value={inputsData ? inputsData.authorName : value.authorName}
                    maxLength={40}
                    onChange={inputsData ? (e) => handleChange(e, setInputsData, inputsData) : (e) => handleChangeRows(e, index)}
                />
                <input
                    type='text'
                    name="authorLastName"
                    className='home_form-item_input'
                    placeholder='Last Name'
                    autoComplete="off"
                    value={inputsData ? inputsData.authorLastName : value.authorLastName}
                    maxLength={40}
                    onChange={inputsData ? (e) => handleChange(e, setInputsData, inputsData) : (e) => handleChangeRows(e, index)}
                />
            </div>
        </>
    );
};

export default Authors;