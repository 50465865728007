import React from 'react';
import {useSelector} from "react-redux";
import {globalSelector} from "../../../redux/slices/global/globalSlice";
import {translation} from "../../../helpers";

const SearchCounters = () => {
    const {homeCounters} = useSelector(globalSelector);

    return (
        <section className='documents-section'>
            <div className='documents-section_item'>
                <h2 className='documents-section_item-number' data-countto={homeCounters.arm_journals_count} data-duration="1000">{homeCounters.arm_journals_count}</h2>
                <p className='documents-section_item-text'>{translation("Journals")}</p>
            </div>
            <div className='documents-section_item'>
                <h2 className='documents-section_item-number' data-countto={homeCounters.authors_count} data-duration="1000">{homeCounters.authors_count}</h2>
                <p className='documents-section_item-text'>{translation("Authors")}</p>
            </div>
            <div className='documents-section_item'>
                <h2 className='documents-section_item-number' data-countto={homeCounters.articles_count} data-duration="1000">{homeCounters.articles_count}</h2>
                <p className='documents-section_item-text'>{translation("Documents")}</p>
            </div>
            <div className='documents-section_item'>
                <h2 className='documents-section_item-number' data-countto={homeCounters.references_count} data-duration="1000">{homeCounters.references_count}</h2>
                <p className='documents-section_item-text'>{translation("Citations")}</p>
            </div>
        </section>
    );
};

export default SearchCounters;