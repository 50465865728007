import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {dispatch, incNbrRec, translation} from "../../helpers";
import HomeForm from "./components/HomeForm";
import {homePageCounters} from "../../helpers/apis";
import SearchCounters from "./components/SearchCounters";
import PartnerLogos from "./components/PartnerLogos";
import {globalSelector, setHomeCounters, setLoader} from "../../redux/slices/global/globalSlice";

function Home() {
    const {homeCounters} = useSelector(globalSelector);

    useEffect(() => {
        if(!Object.keys(homeCounters).length){
            homePageCounters()
                .then((data) => dispatch(setHomeCounters(data)))
                .then(()=> incNbrRec())
                .finally(() => dispatch(setLoader(false)))
        }
    }, []);

    return (
        <div className="home container">
            <main className='home_main'>
                <h2 className='home_title'>{translation("YOUR DOORWAY TO KNOWLEDGE")}</h2>
                <HomeForm/>
                <SearchCounters/>
                <PartnerLogos/>
            </main>
        </div>
    );
}

export default Home;