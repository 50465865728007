import React from 'react';
import {useSelector} from "react-redux/es/index";
import {globalSelector} from "../../../../redux/slices/global/globalSlice";

const ContentJournal = () => {
    const {journalNameById} = useSelector(globalSelector);

    return (
        <article className='info_item journal_info'>
            <h2 className='info_paragraph'>Journal information</h2>
            <h2 className='info_sub-title'>{journalNameById[0]?.name}</h2>
            <span className='line'></span>

            <ul className='info_list'>
                <li className='info_list--item'><span className='info_label'>Journal ID: </span><span
                    className='info_value'>{journalNameById[0]?.j_id}</span></li>
                <li className='info_list--item'><span className='info_label'>ISSN: </span><span
                    className='info_value'>{journalNameById[0]?.issn}</span></li>
                <li className='info_list--item'><span className='info_label'>eISSN: </span><span
                    className='info_value'>{journalNameById[0]?.issn_online}</span></li>
                <li className='info_list--item'><span className='info_label'>Publisher: </span>
                    {
                        journalNameById[0]?.publishers.map((item, i)=>{
                            return(
                                <span className='info_value' key={i}>{item}&nbsp;</span>
                            )
                        })
                    }
                    </li>
                <li className='info_list--item'><span className='info_label'>ASCI Research Areas: </span><span
                    className='info_value'>{journalNameById[0]?.ecoom_categories[0]}</span></li>
                <li className='info_list--item'><span className='info_label'>ASCI Journal Category: </span><span
                    className='info_value'>{journalNameById[0]?.ecoom_categories[1]}</span></li>

            </ul>

        </article>
    );
};

export default ContentJournal;