import React, {useState} from 'react';
import moment from "moment";
import {useHistory, useLocation} from "react-router-dom";
import Calendar from "../../../components/calendar/Calendar";
import ArrowDownSVG from "../../../assets/images/arrowDown.svg";
import SearchAddRows from '../../../components/add-rows/SearchAddRows';
import Collections from "../../../components/collections/Collections";
import {
    dispatch,
    ErrorMessages,
    initialInputValues,
    initialInputValuesAddRow,
    inputsValidation,
    orderByCollection,
    prepareDataAddRows,
    removeQueryDuplicates,
    searchByCollection,
    translation
} from "../../../helpers";
import {
    resetStore,
    searchTypeAction,
    setLoader,
    setOffset,
    setSearchObj
} from "../../../redux/slices/global/globalSlice";
import useOutsideClickHandler from "../../../helpers/custom-hooks/outSideClick";
import queryString from "query-string";

const SearchCollectionsFilter = ({setIsOpen}) => {
    const [toggleArrow, setToggleArrow] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateType, setDateType] = useState(false);
    const [error, setShowError] = useState(false);
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));
    const [dateRange, setDateRange] = useState([
        searchObj?.dateRange[0] ? new Date(searchObj?.dateRange[0]) : null,
        searchObj?.dateRange[1] ? new Date(searchObj?.dateRange[1]) : null
    ]);
    const [startDate] = dateRange;
    const [searchType, setSearchType] = useState('ASCI');
    const [collectionsArr] = useState(['Documents', 'Authors', 'Organizations', 'Journals', 'Cited References']);
    const [collectionType, setCollectionType] = useState(searchObj?.inputsData.ids?.length ? 'Documents' : searchObj.collections);
    const [inputsData, setInputsData] = useState(searchObj.inputsData || initialInputValues(collectionType));
    const [formValues, setFormValues] = useState(searchObj.addedRows || []);
    const queryOption = searchObj.inputsData.articleKey || "";
    let modifiedString = queryOption?.charAt(0).toUpperCase() + queryOption?.slice(1);
    if (modifiedString === "All_fields" || modifiedString === "Document_type") {
        modifiedString = modifiedString?.replace("_", " ")
    }
    const [options] = useState([
        'All Fields',
        'Name',
        'Country',
        'DOI',
        'Keyword',
        'Document Type'
    ]);

    const [option, setOption] = useState(modifiedString?.replace(/f/g, 'F') || options[0]);
    const history = useHistory();
    const location = useLocation();
    const search = queryString.stringify(inputsData);

    const searchPopUpObj = {
        type: searchType,
        collections: collectionType,
        dateRange,
        inputsData,
        addedRows: prepareDataAddRows(formValues)
    };

    const updateURLQuery = (offset, limit) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('offset', offset.toString());
        searchParams.set('limit', limit.toString());
        history.push({search: searchParams.toString()});
    };

    const searchData = (e) => {
        if (inputsValidation(collectionType, inputsData)) {
            e.preventDefault();
            setShowError(false);
            dispatch(resetStore({}));
            dispatch(setLoader(true));
            dispatch(searchTypeAction(collectionType));
            localStorage.setItem("searchObj", JSON.stringify(searchPopUpObj));
            updateURLQuery(0, 10);
            dispatch(setOffset(0));
            localStorage.removeItem('CheckBoxes');
            localStorage.removeItem('searchDataApiArr');
            history.replace({
                pathname: '/search',
                search: `?${search}&searchType=${searchType}&offset=${0}&limit=${10}`,
            });
            removeQueryDuplicates();
            if (startDate) {
                searchByCollection(collectionType, searchType, inputsData, moment(startDate).year(), prepareDataAddRows(formValues), orderByCollection(collectionType), 0, 10)
            } else {
                searchByCollection(collectionType, searchType, inputsData, "", prepareDataAddRows(formValues), orderByCollection(collectionType), 0, 10)
            }
            setIsOpen(false);
        } else {
            setShowError(true)
        }
    };

    const onOutsideClick = () => {
        setToggleArrow(false);
    };
    const ref = useOutsideClickHandler(onOutsideClick);

    const closeCalendar = (e) => {
        e.stopPropagation();
        setShowCalendar(false);
        setDateRange([null, null])
    };

    const targetSection = (e) => {
        e.stopPropagation();
        setDateType(false);
        setToggleArrow(!toggleArrow);
    };

    const dateTypeSelect = (e) => {
        e.stopPropagation();
        setDateType(!dateType);
        setToggleArrow(false);
    };

    const toggleCalendar = (e) => {
        e.stopPropagation();
        setShowCalendar(true);
    };

    const toggleAddRow = (e) => {
        e.stopPropagation();
        setFormValues([...formValues, initialInputValuesAddRow(collectionType)]);
    };

    const handleUserChange = (section) => {
        dispatch(searchTypeAction(section || collectionType));
        setCollectionType(section);
        setShowError(false);
        setToggleArrow(false);
        setInputsData(initialInputValues(section));
        setFormValues([])
    };

    const resetAllFields = () => {
        setSearchType('ASCI');
        setCollectionType('Documents');
        setDateRange([null, null]);
        setShowError(false);
        setDateType(false);
        setToggleArrow(false);
        setShowCalendar(false);
        setInputsData(initialInputValues(collectionType));
        setFormValues([]);
        dispatch(setSearchObj({}));
    };

    return (
        <div className={'w-100'} onKeyPress={e => {
            if (e.key === 'Enter') {
                searchData(e);
            }
        }}>
            <div className='home_form-item collection-item-block w-100'>
                <div className='custom-select' ref={ref}>
                    <div className="user_container" onClick={targetSection}>
                        <p className='dropdown-selected-bold'>{collectionType}</p>
                        <img
                            src={ArrowDownSVG}
                            className={toggleArrow ? 'select-close' : 'select-open'} alt='arrow-icon'
                        />
                    </div>
                    <div className={toggleArrow ? 'drop-down-section section-open' : 'drop-down-section'}
                         style={{zIndex: 6}}>
                        {
                            collectionsArr.map((item, i) => {
                                return (
                                    <p
                                        key={i}
                                        onClick={() => handleUserChange(item)}
                                        className="drop-down-section_button">
                                        {item}
                                    </p>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Collections collection={collectionType} inputsData={inputsData} setInputsData={setInputsData}
                         option={option} options={options} setOption={setOption}/>
            {error &&
            <p style={{color: 'red', marginBottom: "10px", textAlign: "center"}}>{ErrorMessages(collectionType)}</p>}
            <div className='search_actions-rows w-100'>
                {
                    searchObj.dateRange[0] && (
                        <Calendar
                            closeCalendar={closeCalendar}
                            dateRange={dateRange}
                            dateType={dateType}
                            setDateRange={setDateRange}
                            dateTypeSelect={dateTypeSelect}
                        />
                    )
                }

                {
                    formValues?.length ? formValues.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <SearchAddRows
                                    collectionType={collectionType}
                                    setVal={setFormValues}
                                    val={formValues}
                                    item={item}
                                    index={index}
                                />
                            </React.Fragment>
                        )
                    }) : ""
                }
            </div>
            <div className='home_form-actions-row w-100'>
                <div className='home_form-buttons'>
                    <button className='home_form-buttons_item' type='button' onClick={toggleAddRow}>
                        Add Row
                    </button>
                    {
                        !showCalendar &&
                        <button className='home_form-buttons_item' type='button' onClick={toggleCalendar}>
                            {translation("Add Date Range")}
                        </button>
                    }
                </div>
                <div className='home_form-buttons--search'>
                    <button className='home_result-buttons--clear' type='button' onClick={resetAllFields}>CLEAR</button>
                    <button className='home_result-buttons--search' type='button' onClick={searchData}>SEARCH</button>
                </div>
            </div>
        </div>
    );
};

export default SearchCollectionsFilter;