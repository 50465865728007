import React, {useLayoutEffect} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import {removeLngPrefix} from "./helpers";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import ContactUs from "./pages/contact-us/ContactUs";
import About from "./pages/about/About";
import Guidelines from "./pages/guidelines/Guidelines";
import SearchPage from "./pages/search-page/SearchPage";
import ItemArticle from "./pages/search-page/item/ItemArticle";
import ItemJournal from "./pages/search-page/item/itemJournal";
import ItemAuthor from './pages/search-page/item/ItemAuthor';
import ItemCompany from './pages/search-page/item/ItemCompany';
import Loading from "./components/loading/Loading";
import Error from "./components/error-popup/Error";
import {globalSelector} from "./redux/slices/global/globalSlice";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

function App() {
    const {i18n, i18n: {language}} = useTranslation();
    const {error} = useSelector(globalSelector);

    useLayoutEffect(() => {
        const currentPathname = window.location.href.replace(
            window.location.origin,
            ''
        );

        document.documentElement.lang = language;

        const newPathname = `/${language}${removeLngPrefix(currentPathname)}`;

        if (currentPathname !== newPathname) {
            window.history.replaceState({}, '', newPathname);
        }

    }, [language, i18n]);

    return (
        <Router basename={`/${language}/`}>
            <div className="App">
                <Loading/>
                <ScrollToTop/>
                <Header/>
                {error?.data?.message[0] && <Error/>}
                <Switch>
                    <Route path="/" exact={true} component={Home}/>
                    <Route path="/contact-us" exact={true} component={ContactUs}/>
                    <Route path="/about" exact={true} component={About}/>
                    <Route path="/search" exact={true} component={SearchPage}/>
                    <Route path="/author/:id" exact={true} component={ItemAuthor}/>
                    <Route path="/article/:id" exact={true} component={ItemArticle}/>
                    <Route path="/journal/:id" exact={true} component={ItemJournal}/>
                    <Route path="/organization/:id" exact={true} component={ItemCompany}/>
                    <Route path="/search/company" exact={true} component={ItemCompany}/>
                    <Route path="/guide-lines" exact={true} component={Guidelines}/>
                    <Redirect from="*" to="/"/>
                    {/*<Route path='*' exact={true} component={NotFound} />*/}
                </Switch>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
