import React, {useState} from 'react';
import {handleChange} from "../../helpers";
import ArrowDownSVG from "../../assets/images/arrowDown.svg";
import useOutsideClickHandler from "../../helpers/custom-hooks/outSideClick";

const Documents = ({setInputsData, inputsData, handleChangeRows, value, index, option, setOption, options, setVal}) => {
    const [toggleDropDown, setToggleDropDown] = useState(false);

    const onOutsideClick = () => {
        setToggleDropDown(false);
    };

    const ref = useOutsideClickHandler(onOutsideClick);

    return (
        <>
            <div className='home_form-item'>
                <div className='custom-select' ref={ref}>
                    <div className="user_container" onClick={() => setToggleDropDown(!toggleDropDown)}>
                        <p className='dropdown-selected'>{option}</p>
                        <img
                            src={ArrowDownSVG}
                            className={toggleDropDown ? 'select-close' : 'select-open'} alt='arrow-icon'
                        />
                    </div>
                    <div className={toggleDropDown ? 'drop-down-section section-open' : 'drop-down-section'}>
                        {options && options.map((item) => (
                            <p
                                key={item}
                                className='drop-down-section_button'
                                onClick={() => {
                                    setToggleDropDown(false);
                                    if(inputsData){
                                        setInputsData({...inputsData, articleKey: item.toLowerCase().replace(" ", "_")});
                                    }
                                    if(value){
                                        setVal(prevItems => {
                                            return prevItems.map(obj => {
                                                if (obj.name === value.name) {
                                                    return {
                                                        ...obj,
                                                        articleKey: item.toLowerCase().replace(" ", "_")
                                                    };
                                                }
                                                return obj;
                                            });
                                        });
                                    }
                                    setOption(item)
                                }}
                            >
                                {item}
                            </p>
                        ))}
                    </div>
                </div>
            </div>

            <div className='home_form-item'>
                <input
                    type='text'
                    name='document_name'
                    className='home_form-item_input'
                    autoComplete="off"
                    value={inputsData ? inputsData.document_name : value.document_name}
                    maxLength={500}
                    onChange={inputsData ? (e)=> handleChange(e, setInputsData, inputsData) : (e)=> handleChangeRows(e,index)}
                />
            </div>
        </>
    );
};

export default Documents;