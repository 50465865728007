import React from 'react';
import {Link} from "react-router-dom";

const Authors = ({searchResultsAuthor, handleCheckboxChange, checkedValues}) => {
    return searchResultsAuthor?.length ? searchResultsAuthor.map((item, i) => {
        const smallest = Math.min(...item.volumes);
        const largest = Math.max(...item.volumes);
        return (
            <article className='info_item' key={i}>
                <label className='custom-input-checkbox'>
                    <input type="checkbox"
                           onChange={() => handleCheckboxChange(item.id)}
                           checked={checkedValues.includes(Number(item.id))}
                    />
                    <span className="checkmark"></span></label>
                <span className='info_number'>{i + 1}</span>
                <h3 className='info_title'>
                    <Link to={`/author/${item?.id}`}>{item?.last_name} {item?.first_name} {item?.middle_name}</Link>
                </h3>
                <span className="line"></span>
                <div className='info_block'>
                    <p className='info_institution'>{item?.organizations[0]?.name}</p>
                    <ul className="info_list mb-0">
                        <li className="info_list--item"><span className="info_label">Author ID: </span><span
                            className="info_value">{item?.a_id}</span></li>
                        <li className="info_list--item"><span className="info_label">Years of Publications: </span>
                            {
                                smallest === largest ?
                                    <span className="info_value">{smallest}</span> :
                                    <span className="info_value">{smallest}-{largest}</span>
                            }
                        </li>
                        <li className="info_list--item"><span className="info_label">Aknowledgment: </span><span
                            className="info_value">{item?.acknowledgment}</span></li>
                        <li className="info_list--item"><span className="info_label">Number of Documents: </span><span
                            className="info_value">{item?.articles_count}</span></li>
                    </ul>
                </div>
            </article>
        )
    }) : null;
};

export default Authors;