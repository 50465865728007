import React from 'react';
import {useSelector} from "react-redux";
import loader from '../../assets/images/preloader.gif'
import {globalSelector} from "../../redux/slices/global/globalSlice";

const Loading = () => {
    const {loading} = useSelector(globalSelector);

    if (!loading) {
        return null
    }

    return (
        <div className="preloader">
            <img src={loader} alt='loader'/>
        </div>
    );
};

export default Loading;