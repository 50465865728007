import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {globalSelector} from "../../../redux/slices/global/globalSlice";
import {detectSearchTypeUrl, query, translation} from "../../../helpers";
import {ReactComponent as NotificationSVG} from '../../../assets/images/icons/notification.svg';
import SearchCollectionsFilter from '../../search-page/components/SearchCollectionsFilter';
import useOutsideClickHandler from "../../../helpers/custom-hooks/outSideClick";

const SearchForm = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {searchResultsAuthor, searchResultsArticle, searchResultJournals, searchResultOrganizations, searchResultCitedWork} = useSelector(globalSelector);

    const onOutsideClick = () => {
        setIsOpen(false);
    };
    const ref = useOutsideClickHandler(onOutsideClick);

    const detectTotal = () => {
        switch (detectSearchTypeUrl(query())) {
            case "Documents":
                return searchResultsArticle?.total;
            case "Authors":
                return searchResultsAuthor?.total;
            case "Journals":
                return searchResultJournals?.total;
            case "Organizations":
                return searchResultOrganizations?.total;
            case "Cited References":
                return searchResultCitedWork?.total;
            case "ids":
                return searchResultsArticle?.total;
            default:
                return null
        }
    };

    return (
        <form className='search_form tb--prel'>
            <div className='form-row'>
                <div className='search_block col' ref={ref}>
                    <input
                        autoComplete="off"
                        readOnly
                        value={
                            query().document_name ||
                            query().journals ||
                            query().ISSN ||
                            query().authorId ||
                            query().authorName ||
                            query().authorLastName ||
                            query().citedWork ||
                            query().organization_name ||
                        Object.values(query()).find(value => value !== '' ) || ""}
                        className={`search_input`}
                        placeholder="Search ... "
                        onClick={(e) => {
                            e.preventDefault();
                            setIsOpen(!isOpen);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                            }
                        }}/>
                    <span className='search_result'><span className='active'>{detectTotal() || 0}&nbsp;{translation("results")}</span></span>
                    {isOpen && <div className={`search_popup home_form`}>
                        <SearchCollectionsFilter setIsOpen={setIsOpen}/>
                    </div>
                    }
                </div>
                <div className='col-auto'>
                    <button className="btn btn-primary disabled" type="button">{translation("Data Analysis")}</button>
                </div>
                <div className='col-auto'>
                    <button className="btn btn-primary disabled" type="button">{translation("Citation Analysis")}</button>
                </div>
                <div className='col-auto'>
                    <button className="btn btn-outline-primary btn-notification disabled" type="button">
                        <NotificationSVG className='notification-icon'/>
                        {translation("Notifications")}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default SearchForm;