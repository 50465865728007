import React, {useEffect} from 'react';
import './contactUs.scss'
import {dispatch, translation} from "../../helpers";
import {setLoader} from "../../redux/slices/global/globalSlice";

const ContactUs = () => {

    useEffect(() => {
        dispatch(setLoader(false))
    }, []);

    return (
        <main className='contact container'>
            <div className='contact_container'>
                <h1 className='contact_title'>{translation('Contact Us')}</h1>
                <a href='mailto:info@asci.am' className='contact_link'>info@asci.am</a>
                <a href='tel:+374 12 345 678 ' className='contact_link'>+374 12 345 678</a>
                <address className='contact_link'>0014, Yerevan, Republic of Armenia, 1, P. Sevak str.</address>
                <iframe className='contact_map' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d97536.1987856588!2d44.5284352!3d40.186675199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1shy!2sam!4v1680039232272!5m2!1shy!2sam" width="600" height="450" allowFullScreen="" title="contact-us" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </main>
    );
};

export default ContactUs;