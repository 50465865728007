import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {defaultLanguage, getLngFromUrl, resources} from "../helpers";

const lng = getLngFromUrl(window.location.pathname) || i18n.language;

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng,
        whitelist: ['en', 'am'],
        fallbackLng: [defaultLanguage],
        detection: {
            caches: ['cookie']
        },
        interpolation: {
            escapeValue: false
        },
    });

export default i18n;