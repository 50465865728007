import {store} from '../redux/store'
import i18n from "../i18next/i18next";
import common_en from "../i18next/translations/en/common";
import common_am from "../i18next/translations/am/common";
import Cookies from 'js-cookie'
import {
    acknowledgmentFilterArticlesApi,
    armJournalArticlesApi,
    articlesCsvApi,
    articlesCsvBYIDApi,
    authorFilterArticlesApi,
    authorsBYIDCsvApi,
    authorsCsvApi,
    categoriesFilterArticlesApi,
    citedWorkCsvApi,
    citedWorkDocTypeFilterApi,
    citedWorkVolumeFilterApi,
    docTypeFilterArticlesApi,
    fundingFilterArticlesApi,
    journalCsvApi,
    journalCsvByIdApi,
    journalsCategoriesFilterApi,
    journalsDocTypeFilterApi,
    journalSinglePageArticlesByIdApi,
    journalSinglePageCountByIdApi,
    journalSinglePageNameByIdApi,
    journalsPublisherFilterApi,
    journalsVolumeFilterApi,
    languageFilterArticlesApi,
    organizationsCategoriesFilterApi,
    organizationsVolumeFilterApi,
    orgCsvApi,
    orgCsvByIdApi,
    orgFilterArticlesApi,
    orgSinglePageArticlesApi,
    orgSinglePageCountIdApi,
    orgSinglePageNameApi,
    searchCategoriesFiltersAuthorApi,
    searchDataArticlesApi,
    searchDataAuthorApi,
    searchDataCitedWorkApi,
    searchDataJournalsApi,
    searchDataOrganizationsApi,
    searchNamesFiltersAuthorApi,
    searchORGFiltersAuthorApi,
    singlePageAuthorArticles,
    singlePageCountersAuthor,
    singlePageDataAuthor,
    volumeFilterArticlesApi
} from "./apis";
import {
    setAcknowledgmentFilterArticles,
    setArmJournalFilterArticles,
    setAuthorFilterArticles,
    setCategoriesFilterArticles,
    setCategoriesFilterAuthor,
    setCitedWorkDocTypeFilter,
    setCitedWorkVolumeFilter,
    setDocTypeFilterArticles,
    setFundingFilterArticles,
    setJournalsCategoriesFilter,
    setJournalsDocTypeFilter,
    setJournalsPublisherFilter,
    setJournalsVolumeFilter,
    setLanguageFilterArticles,
    setLoader,
    setNamesFiltersAuthor,
    setOrgCategoriesFilter,
    setORGFiltersArticles,
    setORGFiltersAuthor,
    setOrgSingleArticlesById,
    setOrgSinglePageCount,
    setOrgSinglePageName,
    setOrgVolumeFilter,
    setSearchDataArticles,
    setSearchDataAuthor,
    setSearchDataCitedWork,
    setSearchJournalsData,
    setSearchORGData,
    setSingleJournalArticlesById,
    setSingleJournalCountById,
    setSingleJournalNameById,
    setSinglePageAuthorArticles,
    setSinglePageCountersAuthor,
    setSinglePageDataAuthor,
    setVolumeFilterArticles
} from "../redux/slices/global/globalSlice";
import uuid from "react-uuid";

export const defaultLanguage = Cookies.get('i18next') || "en";
export const requestsLanguage = defaultLanguage === "en" ? "eng" : "arm";

export const dispatch = (action) => {
    store.dispatch(action)
};

export const serializableValue = (value) => {
    if (value !== null || undefined)
        return JSON.parse(JSON.stringify(value))
};

export const translation = (key) => {
    return i18n.t(key)
};

export const resources = {
    en: {translation: common_en},
    am: {translation: common_am}
};

export const languages = Object.entries(resources).map(([lang]) => lang);

export const removeLngPrefix = (pathname) => {
    for (let lang of languages) {
        if (pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
            return pathname.replace(`/${lang}`, '')
        }
    }
    return pathname
};

export const getLngFromUrl = pathname => {
    for (let lang of languages) {
        if (pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
            return lang;
        }
    }
    return defaultLanguage;
};

export const initialInputValues = (collection) => {
    switch (collection) {
        case "Documents":
            return {
                document_name: "",
                articleKey: "all_fields",
            };
        case "Authors":
            return {
                authorId: "",
                authorName: "",
                authorLastName: "",
            };
        case "Organizations":
            return {
                organization_name: "",
            };
        case "Journals":
            return {
                journals: "",
                ISSN: "",
                ecoom_category: 0
            };
        case "Cited References":
            return {
                citedWork: "",
            };
        default:
            return null
    }
};

export const initialInputValuesAddRow = (collection) => {
    switch (collection) {
        case "Documents":
            return {
                document_name: "",
                articleKey: "all_fields",
                dropOptions: 'and',
                name: `newRow ${uuid()}`
            };
        case "Authors":
            return {
                authorId: "",
                authorName: "",
                authorLastName: "",
                dropOptions: 'and',
                name: `newRow ${uuid()}`
            };
        case "Organizations":
            return {
                organization_name: "",
                dropOptions: 'and',
                name: `newRow ${uuid()}`
            };
        case "Journals":
            return {
                journals: "",
                ISSN: "",
                dropOptions: 'and',
                ecoom_category: 0,
                name: `newRow ${uuid()}`
            };
        case "Cited References":
            return {
                citedWork: "",
                dropOptions: 'and',
                name: `newRow ${uuid()}`
            };
        default:
            return null
    }
};

export const orderByCollection = (collection) => {
    switch (collection) {
        case "Documents":
            return {
                "year": "asc",
            };
        case "Authors":
            return {
                "first_name": "asc"
            };
        case "Journals":
            return {
                "name": "asc"
            };
        case "Organizations":
            return {
                "name": "asc",
            };
        case "Cited References":
            return {
                "cited_work": "asc",
            };
        case "ids":
            return {
                "year": "asc",
            };
        default:
            return null;
    }
};

export const orderBySelected = (section) => {
    let order_By = orderByCollection(detectSearchTypeUrl(query()));

    switch (section) {
        case "First Name A-Z":
            order_By = {
                "first_name": "asc"
            };
            break;
        case "First Name Z-A":
            order_By = {
                "first_name": "desc"
            };
            break;
        case "Last Name A-Z":
            order_By = {
                "last_name": "asc"
            };
            break;
        case "Last Name Z-A":
            order_By = {
                "last_name": "desc"
            };
            break;
        case "Year A-Z":
            order_By = {
                "year": "asc"
            };
            break;
        case "Year Z-A":
            order_By = {
                "year": "desc"
            };
            break;
        case "Cited Year A-Z":
            order_By = {
                "cited_year": "asc"
            };
            break;
        case "Cited Year Z-A":
            order_By = {
                "cited_year": "desc"
            };
            break;
        case "Citations A-Z":
            order_By = {
                "citation": "asc"
            };
            break;
        case "Citations Z-A":
            order_By = {
                "citation": "desc"
            };
            break;
        case "Name Z-A":
            order_By = {
                "name": "desc"
            };
            break;
        case "Name A-Z":
            order_By = {
                "name": "asc"
            };
            break;
        case "Cited Z-A":
            order_By = {
                "cited_work": "desc",
            };
            break;
        case "Cited A-Z":
            order_By = {
                "cited_work": "asc",
            };
            break;
        default:
            return null
    }

    return order_By
};

export const handleChange = (e, setInputsData, inputsData) => {
    const {name, value} = e.target;
    setInputsData({
        ...inputsData,
        [name]: value,
    });
};

export const inputsValidation = (collection, inputData) => {
    switch (collection) {
        case 'Documents':
            return inputData?.document_name !== "" && inputData?.document_name?.length >= 2;
        case 'Authors':
            return (inputData.authorName || inputData.authorLastName) &&
                (inputData.authorLastName.length >= 3) ||
                (inputData.authorName.length >= 3) ||
                inputData.authorId !== "" && inputData.authorId.length >= 3;
        case 'Organizations':
            return inputData.organization_name !== "" && inputData.organization_name.length >= 3;
        case 'Journals':
            return inputData.journals !== "" || inputData.ISSN !== "" && inputData.journals.length >= 3 || inputData.ISSN.length >= 3;
        case 'Cited References':
            return inputData.citedWork !== "" && inputData.citedWork.length >= 3;
        default:
            return null
    }
};

export const ErrorMessages = (collection) => {
    switch (collection) {
        case 'Documents':
            return "Document name filed is mandatory and at least 3 characters";
        case 'Authors':
            return "Author Id or name last name are mandatory and at least 3 characters";
        case 'Organizations':
            return "Organization name filed is mandatory and at least 3 characters";
        case 'Journals':
            return "Journal name or ISSN filed is mandatory and at least 3 characters";
        case 'Cited References':
            return "Cited work filed is mandatory and at least 3 characters";
        default:
            return null
    }
};

export const prepareParams = (body, newKeys) => {
    const data = {};

    body && Object.keys(body).map(key => {
        if (newKeys[key] && body[key]) {
            data[newKeys[key].toLowerCase()] = body[key].toLowerCase().trim();
        }
        return true
    });
    return data;
};

export const isNumberString = (value) => {
    if (!isNaN(value)) {
        return Number(value);
    }
};

export const query = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const obj = {};
    for (const [key, value] of urlParams) {
        if (typeof obj.ecoom_category === "string") {
            obj["ecoom_category"] = Number(obj.ecoom_category)
        }
        if (isNumberString(value)) {
            obj[key] = Number(value);
        } else {
            obj[key] = value;
        }
    }
    return obj
};

export const prepareDataAddRows = (array) => {
    return array.length && array.map((obj) => {
        const data = prepareParams(obj, requestKeysUpdate);
        const lowercaseData = {};

        for (const key in data) {
            lowercaseData[key.toLowerCase()] = data[key];
        }

        return {
            ...lowercaseData
        };
    });
};
export const incNbrRec = () => {
    const counters = document.querySelectorAll('.documents-section_item-number');

    counters.forEach(counter => {
        const countTo = counter.getAttribute('data-countto');
        const countDuration = parseInt(counter.getAttribute('data-duration'));

        let count = 0;
        const increment = Math.ceil(countTo / (countDuration / 10));

        const interval = setInterval(() => {
            count += increment;
            counter.textContent = count;
            if (count >= countTo) {
                clearInterval(interval);
                counter.textContent = countTo;
            }
        }, 10);
    });
};

export const detectSearchTypeUrl = (query) => {
    const arr = ['Documents', 'Authors', 'Organizations', 'Journals', 'Cited References', 'ids'];
    let matchedValue = "";
    Object.keys(query).filter(key => {
        return arr.map(item => {
            if (key.toLowerCase().startsWith(item.toLowerCase().slice(0, 4))) {
                matchedValue = item;
            }
            return true
        });
    });

    return matchedValue
};

export const requestKeysUpdate = {
    'authorId': 'a_id',
    'authorLastName': 'last_name',
    'authorName': 'first_name',
    'ISSN': 'issn',
    "journals": "name",
    'dropOptions': 'operator',
    'document_name': 'value',
    "articleKey": 'key',
    'Document Type': 'document_type',
    'All Fields': 'all',
    'Country': 'country',
    'Doi': 'doi',
    'Keyword': 'keyword',
    'ecoom_category': 'ecoom_category',
    "organization_name": "name",
    "citedWork": "cited_work",
};

export const singlePageSimilarItems = (id, offset, limit) => {
    const urlParams = window.location.pathname;

    dispatch(setLoader(true));

    if (urlParams.includes("author")) {
        return singlePageAuthorArticles({
            "version": 1, "ids": [Number(id)], "offset": offset,
            "limit": limit
        }, {language: localStorage.getItem("lang")})
            .then((data) => dispatch(setSinglePageAuthorArticles(data)))
            .finally(() => dispatch(setLoader(false)))
    }

    if (urlParams.includes("journal")) {
        return journalSinglePageArticlesByIdApi({
            "version": 1, "id": Number(id), "offset": offset,
            "limit": limit
        }, {language: localStorage.getItem("lang")})
            .then((data) => dispatch(setSingleJournalArticlesById(data)))
            .finally(() => dispatch(setLoader(false)))
    }

    if (urlParams.includes("organization")) {
        return orgSinglePageArticlesApi({
            "version": 1, "id": Number(id), "offset": offset,
            "limit": limit
        }, {language: localStorage.getItem("lang")})
            .then((data) => dispatch(setOrgSingleArticlesById(data)))
            .finally(() => dispatch(setLoader(false)))
    }

};

export const journalSinglePageApi = (id) => {
    dispatch(setLoader(true));

    journalSinglePageNameByIdApi({"version": 1, "id": Number(id)}, {language: localStorage.getItem("lang")})
        .then((data) => dispatch(setSingleJournalNameById(data)));

    journalSinglePageCountByIdApi({"version": 1, "id": Number(id)}, {language: localStorage.getItem("lang")})
        .then((data) => dispatch(setSingleJournalCountById(data)));

    journalSinglePageArticlesByIdApi({
        "version": 1, "id": Number(id), "offset": 0,
        "limit": 10
    }, {language: localStorage.getItem("lang")})
        .then((data) => dispatch(setSingleJournalArticlesById(data)))
        .finally(() => dispatch(setLoader(false)))
};

export const authorSinglePageApi = (id) => {
    dispatch(setLoader(true));

    singlePageDataAuthor({"version": 1, "ids": [Number(id)]}, {language: localStorage.getItem("lang")})
        .then((data) => dispatch(setSinglePageDataAuthor(data)));

    singlePageCountersAuthor({"version": 1, "ids": [Number(id)]}, {language: localStorage.getItem("lang")})
        .then((data) => dispatch(setSinglePageCountersAuthor(data)));

    singlePageAuthorArticles({
        "version": 1, "ids": [Number(id)], "offset": 0,
        "limit": 10
    }, {language: localStorage.getItem("lang")})
        .then((data) => dispatch(setSinglePageAuthorArticles(data)))
        .finally(() => dispatch(setLoader(false)))
};

export const orgSinglePageApi = (id) => {
    dispatch(setLoader(true));

    orgSinglePageNameApi({"version": 1, "id": Number(id)}, {language: localStorage.getItem("lang")})
        .then((data) => dispatch(setOrgSinglePageName(data)));

    orgSinglePageCountIdApi({"version": 1, "id": Number(id)}, {language: localStorage.getItem("lang")})
        .then((data) => dispatch(setOrgSinglePageCount(data)));

    orgSinglePageArticlesApi({
        "version": 1, "id": Number(id), "offset": 0,
        "limit": 10
    }, {language: localStorage.getItem("lang")})
        .then((data) => dispatch(setOrgSingleArticlesById(data)))
        .finally(() => dispatch(setLoader(false)))
};

export const articleApiCalls = (searchType, inputsData, startDate, addedRows, orderBy, offset, limit) => {
    const obj = {
        "version": searchType === 'ASCI' ? 1 : 2,
        "query": [prepareParams(inputsData, requestKeysUpdate)],
        "offset": offset,
        "limit": limit,
    };

    const mergedArr = addedRows?.length ? [prepareParams(inputsData, requestKeysUpdate)].concat(addedRows) : [prepareParams(inputsData, requestKeysUpdate)];

    const filterObj = {
        "version": searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr
    };

    if (orderBy) {
        obj['order_by'] = orderBy
    }

    if (addedRows?.length) {
        obj['query'] = mergedArr;
    }

    if (startDate) {
        obj['date_range'] = {"start_date": startDate}
    }

    if (inputsData.ids && Array.isArray(inputsData.ids) && inputsData.ids.length > 0) {
        delete obj["query"];
        delete filterObj["query"];
        filterObj["ids"] = inputsData.ids;
        obj["ids"] = inputsData.ids
    }

    searchDataArticlesApi(obj)
        .then((data) => {
            dispatch(setSearchDataArticles(data));
            localStorage.setItem("lang", data.language);

            volumeFilterArticlesApi(filterObj, {language: data.language}).then((data) => dispatch(setVolumeFilterArticles(data)));

            docTypeFilterArticlesApi(filterObj, {language: data.language}).then((data) => dispatch(setDocTypeFilterArticles(data)));

            armJournalArticlesApi(filterObj, {language: data.language}).then((data) => dispatch(setArmJournalFilterArticles(data)));

            categoriesFilterArticlesApi(filterObj, {language: data.language}).then((data) => dispatch(setCategoriesFilterArticles(data)));

            authorFilterArticlesApi(filterObj, {language: data.language}).then((data) => dispatch(setAuthorFilterArticles(data)));

            orgFilterArticlesApi(filterObj, {language: data.language}).then((data) => dispatch(setORGFiltersArticles(data)));

            languageFilterArticlesApi(filterObj, {language: data.language}).then((data) => dispatch(setLanguageFilterArticles(data)));

            acknowledgmentFilterArticlesApi(filterObj, {language: data.language}).then((data) => dispatch(setAcknowledgmentFilterArticles(data)));

            fundingFilterArticlesApi(filterObj, {language: data.language}).then((data) => dispatch(setFundingFilterArticles(data)));
        })

        .finally(() => dispatch(setLoader(false)));
};

export const journalsApiCalls = (searchType, inputsData, startDate, addedRows, orderBy, offset, limit) => {

    const mergedArr = addedRows?.length ? [prepareParams(inputsData, requestKeysUpdate)].concat(addedRows) :
        [prepareParams(inputsData, requestKeysUpdate)];

    const obj = {
        "version": searchType === 'ASCI' ? 1 : 2,
        "query": [prepareParams(inputsData, requestKeysUpdate)],
        "offset": offset,
        "limit": limit,
    };

    if (orderBy) {
        obj['order_by'] = orderBy
    }

    if (addedRows?.length) {
        obj['query'] = mergedArr;
    }

    if (startDate) {
        obj['date_range'] = {"start_date": startDate}
    }

    searchDataJournalsApi(obj)
        .then((data) => {
            dispatch(setSearchJournalsData(data));
            localStorage.setItem("lang", data.language);

            journalsCategoriesFilterApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setJournalsCategoriesFilter(data)));

            // journalsDocTypeFilterApi({
            //     "version": searchType === 'ASCI' ? 1 : 2,
            //     "query": mergedArr
            // }, {language: data.language})
            //     .then((data) => dispatch(setJournalsDocTypeFilter(data)));

            journalsPublisherFilterApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setJournalsPublisherFilter(data)));

            journalsVolumeFilterApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setJournalsVolumeFilter(data)));
        })

        .finally(() => dispatch(setLoader(false)));
};

export const orgApiCalls = (searchType, inputsData, startDate, addedRows, orderBy, offset, limit) => {

    const mergedArr = addedRows?.length ? [prepareParams(inputsData, requestKeysUpdate)].concat(addedRows) :
        [prepareParams(inputsData, requestKeysUpdate)];

    const obj = {
        "version": searchType === 'ASCI' ? 1 : 2,
        "query": [prepareParams(inputsData, requestKeysUpdate)],
        "offset": offset,
        "limit": limit,
    };

    if (orderBy) {
        obj['order_by'] = orderBy
    }

    if (addedRows?.length) {
        obj['query'] = mergedArr;
    }

    if (startDate) {
        obj['date_range'] = {"start_date": startDate}
    }

    searchDataOrganizationsApi(obj)
        .then((data) => {
            dispatch(setSearchORGData(data));
            localStorage.setItem("lang", data.language);

            organizationsVolumeFilterApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setOrgVolumeFilter(data)));

            organizationsCategoriesFilterApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setOrgCategoriesFilter(data)));
        })
        .finally(() => dispatch(setLoader(false)));
};

export const authorApiCalls = (searchType, inputsData, startDate, addedRows, orderBy, offset, limit) => {

    const mergedArr = addedRows?.length ? [prepareParams(inputsData, requestKeysUpdate)].concat(addedRows) :
        [prepareParams(inputsData, requestKeysUpdate)];

    const obj = {
        "version": searchType === 'ASCI' ? 1 : 2,
        "query": [prepareParams(inputsData, requestKeysUpdate)],
        "offset": offset,
        "limit": limit,
    };

    if (orderBy) {
        obj['order_by'] = orderBy
    }

    if (addedRows?.length) {
        obj['query'] = mergedArr;
    }

    if (startDate) {
        obj['date_range'] = {"start_date": startDate}
    }

    searchDataAuthorApi(obj)
        .then((data) => {
            dispatch(setSearchDataAuthor(data));
            localStorage.setItem("lang", data.language);

            searchORGFiltersAuthorApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setORGFiltersAuthor(data)));

            searchNamesFiltersAuthorApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setNamesFiltersAuthor(data)));

            searchCategoriesFiltersAuthorApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setCategoriesFilterAuthor(data)));
        })

        .finally(() => dispatch(setLoader(false)));
};

export const citedWorkApiCalls = (searchType, inputsData, startDate, addedRows, orderBy, offset, limit) => {

    const mergedArr = addedRows?.length ? [prepareParams(inputsData, requestKeysUpdate)].concat(addedRows) :
        [prepareParams(inputsData, requestKeysUpdate)];

    const obj = {
        "version": searchType === 'ASCI' ? 1 : 2,
        "query": [prepareParams(inputsData, requestKeysUpdate)],
        "offset": offset,
        "limit": limit,
    };

    if (orderBy) {
        obj['order_by'] = orderBy
    }

    if (addedRows?.length) {
        obj['query'] = mergedArr;
    }

    if (startDate) {
        obj['date_range'] = {"start_date": startDate}
    }

    searchDataCitedWorkApi(obj)
        .then((data) => {
            dispatch(setSearchDataCitedWork(data));
            localStorage.setItem("lang", data.language);

            citedWorkDocTypeFilterApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setCitedWorkDocTypeFilter(data)));

            citedWorkVolumeFilterApi({
                "version": searchType === 'ASCI' ? 1 : 2,
                "query": mergedArr
            }, {language: data.language})
                .then((data) => dispatch(setCitedWorkVolumeFilter(data)));
        })

        .finally(() => dispatch(setLoader(false)));
};

export const searchByOnlyMainApi = (collectionType, orderBy, limit, offset) => {
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));

    const obj = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": [prepareParams(query(), requestKeysUpdate)],
        "offset": offset,
        "limit": limit,
    };

    if (searchObj.inputsData.ids && Array.isArray(searchObj.inputsData.ids) && searchObj.inputsData.ids.length > 0) {
        delete obj["query"];
        obj["ids"] = searchObj.inputsData.ids
    }

    if (orderBy) {
        obj["order_by"] = orderBy
    }

    switch (collectionType) {
        case 'Documents':
            searchDataArticlesApi(obj)
                .then((data) => dispatch(setSearchDataArticles(data)))
                .finally(() => dispatch(setLoader(false)));
            break;
        case 'Authors':
            searchDataAuthorApi(obj)
                .then((data) => dispatch(setSearchDataAuthor(data)))
                .finally(() => dispatch(setLoader(false)));
            break;
        case 'Journals':
            searchDataJournalsApi(obj)
                .then((data) => dispatch(setSearchJournalsData(data)))
                .finally(() => dispatch(setLoader(false)));
            break;
        case 'Organizations':
            searchDataOrganizationsApi(obj)
                .then((data) => dispatch(setSearchORGData(data)))
                .finally(() => dispatch(setLoader(false)));
            break;
        case 'Cited References':
            searchDataCitedWorkApi(obj)
                .then((data) => dispatch(setSearchDataCitedWork(data)))
                .finally(() => dispatch(setLoader(false)));
            break;
        case 'ids':
            searchDataArticlesApi(obj)
                .then((data) => dispatch(setSearchDataArticles(data)))
                .finally(() => dispatch(setLoader(false)));
            break;
        default:
            return null
    }
};

export const searchByCollection = (collectionType, searchType, inputsData, startDate, addedRows, orderBy, offset, limit) => {
    switch (collectionType) {
        case 'Documents':
            articleApiCalls(searchType, inputsData, startDate, addedRows, orderBy, offset, limit);
            break;
        case 'Authors':
            authorApiCalls(searchType, inputsData, startDate, addedRows, orderBy, offset, limit);
            break;
        case 'Journals':
            journalsApiCalls(searchType, inputsData, startDate, addedRows, orderBy, offset, limit);
            break;
        case 'Organizations':
            orgApiCalls(searchType, inputsData, startDate, addedRows, orderBy, offset, limit);
            break;
        case 'Cited References':
            citedWorkApiCalls(searchType, inputsData, startDate, addedRows, orderBy, offset, limit);
            break;
        case 'ids':
            articleApiCalls(searchType, inputsData, startDate, addedRows, orderBy, offset, limit);
            break;
        default:
            return null
    }
};

export const removeQueryDuplicates = () => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const params = new URLSearchParams(url.search);
    const uniqueParams = {};

    for (const [key, value] of params.entries()) {
        if (!uniqueParams[key]) {
            uniqueParams[key] = value;
        }
    }

    url.search = new URLSearchParams(uniqueParams).toString();
    const newURL = url.toString();

    window.history.replaceState({}, document.title, newURL);
};

export const exportAsCsv = (checkedId) => {
    const lang = localStorage.getItem("lang");
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));
    const filters = JSON.parse(localStorage.getItem("searchDataApiArr"));
    const version = searchObj.type === "ASCI" ? 1 : 2;
    const mergedArr = searchObj.addedRows?.length ? [prepareParams(searchObj.inputsData, requestKeysUpdate)].concat(searchObj.addedRows) :
        [prepareParams(searchObj.inputsData, requestKeysUpdate)];

    const obj = {version, query: mergedArr};

    if (searchObj.inputsData.ids && Array.isArray(searchObj.inputsData.ids) && searchObj.inputsData.ids.length > 0) {
        delete obj["query"];
        obj["ids"] = searchObj.inputsData.ids
    }

    const downloadCsvFile = (file, filename) => {
        const csvURL = URL.createObjectURL(new Blob([file], {type: 'text/csv'}));
        const link = document.createElement('a');
        link.setAttribute('href', csvURL);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    switch (detectSearchTypeUrl(query())) {
        case "Documents":
            if (filters?.ecoom_categories.length) {
                obj["ecoom_categories"] = filters?.ecoom_categories;
            }

            if (filters?.organizations.length) {
                obj["organizations"] = filters?.organizations;
            }

            if (filters?.authors.length) {
                obj["authors"] = filters?.authors;
            }

            if (filters?.volumes.length) {
                obj["volumes"] = filters?.volumes;
            }

            if (filters?.document_types.length) {
                obj["document_types"] = filters?.document_types;
            }

            if (filters?.arm_journals.length) {
                obj["arm_journals"] = filters?.arm_journals;
            }

            if (filters?.acknowledgements.length) {
                obj["acknowledgements"] = filters?.acknowledgements;
            }

            if (filters?.languages.length) {
                obj["languages"] = filters?.languages;
            }

            if (filters?.fundings.length) {
                obj["fundings"] = filters?.fundings;
            }

            break;

        case "Authors":

            if (filters?.ecoom_categories.length) {
                obj["ecoom_categories"] = filters?.ecoom_categories;
            }

            if (filters?.organizations.length) {
                obj["organizations"] = filters?.organizations;
            }

            if (filters?.ids.length) {
                obj["names"] = filters?.ids;
            }

            break;

        case "Journals":

            if (filters?.ecoom_categories.length) {
                obj["ecoom_categories"] = filters?.ecoom_categories;
            }

            if (filters?.publishers.length) {
                obj["publishers"] = filters?.publishers;
            }

            if (filters?.document_types.length) {
                obj["document_types"] = filters?.document_types;
            }

            if (filters?.volumes.length) {
                obj["volumes"] = filters?.volumes;
            }

            break;

        case "Organizations":

            if (filters?.ecoom_categories.length) {
                obj["ecoom_categories"] = filters?.ecoom_categories;
            }

            if (filters?.volumes.length) {
                obj["volumes"] = filters?.volumes;
            }

            break;

        case "Cited References":

            if (filters?.docType.length) {
                obj["document_types"] = filters?.docType;
            }

            if (filters?.volumes.length) {
                obj["cited_publ_years"] = filters?.volumes;
            }
            break;

        case "ids":
            if (filters?.ecoom_categories.length) {
                obj["ecoom_categories"] = filters?.ecoom_categories;
            }

            if (filters?.organizations.length) {
                obj["organizations"] = filters?.organizations;
            }

            if (filters?.authors.length) {
                obj["authors"] = filters?.authors;
            }

            if (filters?.volumes.length) {
                obj["volumes"] = filters?.volumes;
            }

            if (filters?.document_types.length) {
                obj["document_types"] = filters?.document_types;
            }

            if (filters?.arm_journals.length) {
                obj["arm_journals"] = filters?.arm_journals;
            }

            if (filters?.acknowledgements.length) {
                obj["acknowledgements"] = filters?.acknowledgements;
            }

            if (filters?.languages.length) {
                obj["languages"] = filters?.languages;
            }

            if (filters?.fundings.length) {
                obj["fundings"] = filters?.fundings;
            }

            break;

        default:
            return null;
    }

    const exportCsv = async (apiFn) => {

        if (Array.isArray(checkedId)) {
            apiFn({version, ids: checkedId}, {language: lang, responseType: "blob"})
                .then((response) => {
                    downloadCsvFile(response, searchObj.collections);
                });
        } else {
            apiFn(obj, {language: lang, responseType: "blob"})
                .then((response) => {
                    downloadCsvFile(response, searchObj.collections);
                });
        }
    };

    switch (detectSearchTypeUrl(query())) {
        case "Documents":
            return exportCsv(articlesCsvApi, checkedId);

        case "Authors":
            return exportCsv(authorsCsvApi, checkedId);

        case "Journals":
            return exportCsv(journalCsvApi, checkedId);

        case "Organizations":
            return exportCsv(orgCsvApi, checkedId);

        case "Cited References":
            return exportCsv(citedWorkCsvApi, checkedId);

        case "ids":
            return exportCsv(articlesCsvApi, checkedId);

        default:
            return null;
    }
};

export const exportAsCsvSinglePage = (checkedId) => {
    const lang = localStorage.getItem("lang");
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));
    const urlParams = window.location.pathname;

    const downloadCsvFile = (file, filename) => {
        const csvURL = URL.createObjectURL(new Blob([file], {type: 'text/csv'}));
        const link = document.createElement('a');
        link.setAttribute('href', csvURL);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportCsv = async (apiFn, filename) => {

        const version = searchObj.type === "ASCI" ? 1 : 2;
        try {
            const responseBlob = urlParams.includes("article") || urlParams.includes("author") ?
                await apiFn({version, ids: [checkedId]}, {language: lang, responseType: "blob"}) :
                await apiFn({version, id: checkedId}, {language: lang, responseType: "blob"});

            const csvFilename = `${filename}.csv`;
            downloadCsvFile(responseBlob, csvFilename);
        } catch (error) {
            console.error(error);
        }
    };

    if (urlParams.includes("article")) {
        return exportCsv(articlesCsvBYIDApi, checkedId);
    }

    if (urlParams.includes("author")) {
        return exportCsv(authorsBYIDCsvApi, checkedId);
    }

    if (urlParams.includes("journal")) {
        return exportCsv(journalCsvByIdApi, checkedId);
    }

    if (urlParams.includes("organization")) {
        return exportCsv(orgCsvByIdApi, checkedId);
    }

};