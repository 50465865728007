import React from 'react';
import moment from "moment";
import './footer.scss'
import FooterLogo from './../../assets/images/footer-logo.png'
import {translation} from "../../helpers";

function Footer() {
    return (
        <footer className="footer container">
            <div className="footer_content">
                <div className='footer_item'>
                    <img className='footer_logo' src={FooterLogo} alt='logo'/>
                </div>
            </div>
            <span className='footer-line'/>
            <p className='footer_copyright'>@ {moment().year()} {translation('Center for Scientific Information Analysis and Monitoring')}</p>
        </footer>
    );
}

export default Footer;
