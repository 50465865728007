import React, {useEffect, useState} from 'react';
import {handleChange, translation} from "../../helpers";
import ArrowDownSVG from "../../assets/images/arrowDown.svg";
import useOutsideClickHandler from "../../helpers/custom-hooks/outSideClick";
import {journalHomeCategoriesApi} from "../../helpers/apis";

const Journals = ({setInputsData, inputsData, handleChangeRows, index, value, setVal}) => {
    const [toggleDropDown, setToggleDropDown] = useState(false);
    const [activeCategoriesName, setActiveCategoriesName] = useState();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        journalHomeCategoriesApi()
            .then((data) => {
            setCategories(data)
        })
    }, []);

    const onOutsideClick = () => {
        setToggleDropDown(false);
    };
    const ref = useOutsideClickHandler(onOutsideClick);

    return (
        <>
            <div className='home_form-item'>
                <input
                    type='text'
                    className='home_form-item_input'
                    name="journals"
                    placeholder='Journal Name'
                    autoComplete="off"
                    value={inputsData ? inputsData.journals : value.journals}
                    maxLength={500}
                    onChange={inputsData ? (e) => handleChange(e, setInputsData, inputsData) : (e) => handleChangeRows(e, index)}
                />
            </div>
            <div className='home_form-item'>
                <input
                    type='text'
                    className='home_form-item_input'
                    name="ISSN"
                    placeholder='ISSN'
                    autoComplete="off"
                    value={inputsData ? inputsData.ISSN : value.ISSN}
                    onChange={inputsData ? (e) => handleChange(e, setInputsData, inputsData) : (e) => handleChangeRows(e, index)}
                />
            </div>
            <div className='home_form-item'>
                <span>{translation('ASCI Subject Categories')}</span>
                <div className='custom-select' ref={ref}>
                    <div className="user_container" onClick={() => setToggleDropDown(!toggleDropDown)}>
                        <p className='dropdown-selected-bold'>{activeCategoriesName || translation('ASCI Subject Categories')}</p>
                        <img
                            src={ArrowDownSVG}
                            className={toggleDropDown ? 'select-close' : 'select-open'} alt='arrow-icon'
                        />
                    </div>

                    <div className={toggleDropDown ? 'drop-down-section section-open' : 'drop-down-section'}>
                        {
                            categories?.length ? categories.map((item) => {
                                return (
                                    <p className='drop-down-section_button'
                                       onClick={()=> {
                                           if(inputsData){
                                               setInputsData({...inputsData, ecoom_category: Number(item.id)});
                                               setActiveCategoriesName(item.name);
                                           }
                                           if(value){
                                               setVal(prevItems => {
                                                   return prevItems.map(obj => {
                                                       if (obj.name === value.name) {
                                                           return {
                                                               ...obj,
                                                               ecoom_category: Number(item.id)
                                                           };
                                                       }
                                                       return obj;
                                                   });
                                               });
                                               setActiveCategoriesName(item.name);
                                           }
                                               setToggleDropDown(false);
                                       }}
                                       key={item.id}>{item.name}</p>
                                )
                            }) : ""
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Journals;