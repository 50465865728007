import React from 'react';
import Documents from "./Documents";
import Authors from "./Authors";
import Organizations from "./Organizations";
import Journals from "./Journals";
import CitedReferences from "./CitedReferences";

const Collections = ({collection, setInputsData, inputsData, option, setOption, options}) => {
    switch (collection) {
        case 'Documents':
            return <Documents setInputsData={setInputsData} inputsData={inputsData} option={option} options={options} setOption={setOption}/>;
        case 'Authors':
            return <Authors setInputsData={setInputsData} inputsData={inputsData}/>;
        case 'Organizations':
            return <Organizations setInputsData={setInputsData} inputsData={inputsData}/>;
        case 'Journals':
            return <Journals setInputsData={setInputsData} inputsData={inputsData}/>;
        case 'Cited References':
            return <CitedReferences setInputsData={setInputsData} inputsData={inputsData}/>;
        default:
            return null;
    }
};

export default Collections;