import React from 'react';
import Author from "./Author";
import Article from "./Article";
import Organization from "./Organization";
import Journals from "./Journals";
import {detectSearchTypeUrl, query} from "../../../../helpers";
import CitedWork from "./CitedWork";

const FilterForm = ({setCheckedValues}) => {
    switch (detectSearchTypeUrl(query())) {
        case "Documents":
            return <Article setCheckedValues={setCheckedValues}/>;
        case "Authors":
            return <Author setCheckedValues={setCheckedValues}/>;
        case "Organizations":
            return <Organization setCheckedValues={setCheckedValues}/>;
        case "Journals":
            return <Journals setCheckedValues={setCheckedValues}/>;
        case "Cited References":
            return <CitedWork setCheckedValues={setCheckedValues}/>;
        case "ids":
            return <Article setCheckedValues={setCheckedValues}/>;
        default:
            return null
    }
};

export default FilterForm;