import React from 'react';
import PartnerItem1 from "../../../assets/images/csiam.png";
import PartnerItem2 from "../../../assets/images/iiap.png";
import PartnerItem3 from "../../../assets/images/scs.png";
import PartnerItem4 from "../../../assets/images/ifix.png";

const PartnerLogos = () => {
    return (
        <section className='partners-section'>
            <a href='http://scs.am/' target='_blank' rel="noreferrer" className='partner_item'>
                <img src={PartnerItem3} className='partner_logo' alt='logo'/>
            </a>
            <a href='https://iiap.sci.am/' rel="noreferrer" target='_blank' className='partner_item'>
                <img src={PartnerItem2} className='partner_logo' alt='logo'/>
            </a>
            <a href='http://csiam.sci.am/' rel="noreferrer" target='_blank' className='partner_item'>
                <img src={PartnerItem1} className='partner_logo' alt='logo'/>
            </a>
            <a href='https://ifixgroup.com/' rel="noreferrer" target='_blank' className='partner_item'>
                <img src={PartnerItem4} className='partner_logo' alt='logo'/>
            </a>
        </section>
    );
};

export default PartnerLogos;