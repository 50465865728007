import { useEffect, useRef } from 'react';

function useOutsideClickMultipleHandler(onOutsideClick) {
    const refs = useRef([]);

    useEffect(() => {
        function handleClickOutside(event) {
            const isOutsideClick = refs.current.every((ref) => (
                ref && ref.current && !ref.current.contains(event.target)
            ));

            if (isOutsideClick) {
                onOutsideClick();
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onOutsideClick]);

    return refs;
}

export default useOutsideClickMultipleHandler;