import axios from 'axios';
import {dispatch, serializableValue} from "../index";
import {setError, setLoader} from "../../redux/slices/global/globalSlice";

export const AxiosInstance = (method, url, params = {}) => {
    const {body, headers} = params;

    return new Promise(async (resolve) => {
        const {data} = await axios({
            method,
            url,
            data: body || {},
            responseType: params.responseType,
            headers: headers || {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        }).catch(async error => {
            if (error.response) {
                await dispatch(setError(serializableValue(error.response)));
                await dispatch(setLoader(false))
            }
            return {data: null};
        });

        if (data) {
            try {
                const result = await data;
                resolve(result);
            } catch (error) {
                const result = await data.error;
                resolve(result);
            }
        }
    });
};
