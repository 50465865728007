import React from 'react';
import {useSelector} from "react-redux/es/index";
import {globalSelector} from "../../../../redux/slices/global/globalSlice";

const ContentAuthor = () => {
    const {singlePageAuthor} = useSelector(globalSelector);

    return (
        <div className="content-form h-100">
            <div className='info_list h-100'>
                <article className='info_item h-100'>
                    <h3 className='info_title mb-5'>{singlePageAuthor[0]?.last_name} {singlePageAuthor[0]?.first_name} {singlePageAuthor[0]?.middle_name}</h3>
                    <h4 className="info_author"><span>Author ID: </span>{singlePageAuthor[0]?.a_id}</h4>
                    <span className='line'></span>
                    <p className='info_caption'>Author’s names</p>
                    {
                        singlePageAuthor[0]?.author_names.length ? singlePageAuthor[0]?.author_names.map((item) => {
                            return (
                                <p className='info_resulte mb-15' key={item}>{item}</p>
                            )
                        }) : ""
                    }
                    <p className='info_caption'>Author’s Organization’s</p>
                    <ul className='info_list tb--flex-grow '>
                        {
                            singlePageAuthor[0]?.organizations.length ? singlePageAuthor[0]?.organizations.map((item) => {
                                return (
                                    <li className='info_list--item' key={item.id}><span
                                        className='info_label'>2005-2021 </span><span
                                        className='info_value'>{item.name}</span></li>
                                )
                            }) : ""
                        }
                    </ul>
                </article>
            </div>
        </div>
    );
};

export default ContentAuthor;