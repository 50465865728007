import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom'
import ContentItem from "../components/Articles/ContentItem";
import ArrowDownSVG from "../../../assets/images/arrowDown.svg";
import {ReactComponent as EducationSVG} from "../../../assets/images/icons/education.svg";
import {dispatch, exportAsCsvSinglePage, translation} from "../../../helpers";
import {singlePageArticles} from "../../../helpers/apis";
import {globalSelector, setLoader, setSinglePageArticles} from "../../../redux/slices/global/globalSlice";
import {useSelector} from "react-redux";
import {ReactComponent as ArrowSVG} from "../../../assets/images/icons/arrow.svg";
import DocumentsCitations from "../components/Citations/Documents";

const ItemArticle = () => {
    const [exportResultsArr] = useState(['Export', 'Export_01', 'Export_02', 'Export_03']);
    const [exportResultsOption, setExportResultsOption] = useState(exportResultsArr[0]);
    const obj = useSelector(globalSelector);
    const [active, setActive] = useState(null);
    const history = useHistory();
    const {id} = useParams();


    const ContentFilterItemEnum = {
        ExportResults: 0,
        PageSize: 1,
        SortBy: 2
    };

    const targetSection = (id) => {
        if (active === id) {
            setActive(null);
        } else {
            setActive(id);
        }
    };

    const backToSearch = () => {
        if (history.action === "POP") {
            history.push('/')
        } else {
            history.goBack()
        }
    };

    useEffect(() => {
        dispatch(setSinglePageArticles([]));
        dispatch(setLoader(true));
        singlePageArticles({"id": Number(id)}, {language: localStorage.getItem("lang")})
            .then((data) => dispatch(setSinglePageArticles(data)))
            .finally(() => dispatch(setLoader(false)))
    }, [id]);

    return (
        <main className='content_item_container'>
            <section className='content container'>

                <div className='content_filter'>
                    <div className="form-row tb--align-center">

                        <div className="col-auto tb--flex flex-gap-10">
                            <button onClick={backToSearch} className='back-to-link'><ArrowSVG/> Back to the Search
                            </button>
                            {
                                obj.singlePageArticles[0]?.paper ? <>
                                    <a href={`${obj.singlePageArticles[0]?.paper}`} target="_bank" className="btn-info"
                                       type="button">Full text</a>
                                    <button className="btn-education btn-info" type="button"><EducationSVG/></button>
                                </> : ""
                            }
                        </div>
                        <div className='col-auto ml-auto tb--flex flex-gap-10 tb--align-center'>
                            <div className='content_filter-item'>
                                <div className='custom-select'>
                                    <div className="user_container"
                                         onClick={() => targetSection(ContentFilterItemEnum.ExportResults)}>
                                        <p className='dropdown-selected-bold'>{exportResultsOption}</p>
                                        <img src={ArrowDownSVG}
                                             className={active === ContentFilterItemEnum.ExportResults ? 'select-close' : 'select-open'}
                                             alt='arrow-icon'/>
                                    </div>
                                    <div
                                        className={`drop-down-section scroller ${active === ContentFilterItemEnum.ExportResults ? 'section-open' : ''}`}>
                                        <p onClick={() => {
                                            setActive(null);
                                            exportAsCsvSinglePage(Number(id))
                                        }}
                                           className="drop-down-section_button">
                                            {translation(" Export as csv")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/*<Pagination/>*/}
                        </div>

                    </div>
                </div>

                <div className="form-row content-row">
                    <div className="col content-col">
                        <ContentItem/>
                    </div>

                    <div className="col citations-col">
                        <DocumentsCitations/>
                    </div>
                </div>

            </section>
        </main>
    );
};

export default ItemArticle;