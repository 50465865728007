import React from 'react';
import {DateRangePicker} from "rsuite";

const DateRange = ({setDateRange, dateRange}) => {
    return (
        <div className='date-range home_form-full-width' onClick={(e)=> e.stopPropagation()}>
            <DateRangePicker
                placeholder="All years (1975-2023)"
                format='dd-MM-yyyy'
                defaultValue={dateRange}
                value={dateRange}
                onSelect={({}, e)=> e.stopPropagation()}
                onOk={(value, e) => {
                    e.stopPropagation();
                    setDateRange(value)
                }}/>
        </div>
    );
};

export default DateRange;