import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import queryString from 'query-string';
import {
    dispatch,
    ErrorMessages,
    initialInputValues,
    initialInputValuesAddRow,
    inputsValidation,
    orderByCollection,
    prepareDataAddRows,
    searchByCollection,
    serializableValue,
    translation
} from "../../../helpers";
import {
    globalSelector,
    resetStore,
    searchTypeAction,
    setLoader,
    setOffset,
    setSearchObj
} from "../../../redux/slices/global/globalSlice";
import Calendar from "./Calendar";
import AddRows from "../../../components/add-rows/AddRows";
import Collections from "../../../components/collections/Collections";
import ArrowDownSVG from "../../../assets/images/arrowDown.svg";
import useOutsideClickHandler from "../../../helpers/custom-hooks/outSideClick";
import {useTranslation} from "react-i18next";

const HomeForm = () => {
    const {i18n: {language}} = useTranslation();
    const [toggleArrow, setToggleArrow] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateType, setDateType] = useState(false);
    const [error, setShowError] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate] = dateRange;
    const [searchType, setSearchType] = useState('ASCI');
    const [collectionsArr] = useState(['Documents', 'Authors', 'Organizations', 'Journals', 'Cited References']);
    const [collectionType, setCollectionType] = useState(collectionsArr[0]);
    const [formValues, setFormValues] = useState([]);
    const [inputsData, setInputsData] = useState(initialInputValues(collectionType));
    const {offset, limit} = useSelector(globalSelector);
    const [options] = useState([
        'All Fields',
        'Name',
        'Country',
        'Doi',
        'Keyword',
        'Document Type'
    ]);

    const history = useHistory();

    const [option, setOption] = useState(options[0]);
    const search = queryString.stringify(inputsData);

    const searchObj = {
        type: searchType,
        collections: collectionType,
        dateRange,
        inputsData,
        addedRows: prepareDataAddRows(formValues)
    };

    const searchData = (e) => {
        e.preventDefault();
        if (inputsValidation(collectionType, inputsData)) {
            setShowError(false);
            dispatch(resetStore({}));
            dispatch(setLoader(true));
            dispatch(setSearchObj(serializableValue(searchObj)));
            localStorage.setItem("searchObj", JSON.stringify(searchObj));
            dispatch(searchTypeAction(collectionType));
            dispatch(setOffset(0));
            localStorage.removeItem('CheckBoxes');
            localStorage.removeItem('searchDataApiArr');
            history.push({
                pathname: `/search`,
                search: `?${search}&searchType=${searchType}&offset=${Number(offset)}&limit=${Number(limit)}`,
            });
            if (formValues.length) {
                searchByCollection(collectionType, searchType, inputsData, startDate ? moment(startDate).year() : "", prepareDataAddRows(formValues), orderByCollection(collectionType), 0, 10)
            } else {
                searchByCollection(collectionType, searchType, inputsData, startDate ? moment(startDate).year() : "", [], orderByCollection(collectionType), 0, 10)
            }
        } else {
            setShowError(true)
        }
    };

    useEffect(() => {
        localStorage.setItem("searchObj", JSON.stringify({}));
        localStorage.setItem("lang", language === "en" ? "eng" : "arm");
        localStorage.removeItem('searchDataApiArr');
        localStorage.removeItem('CheckBoxes');
        localStorage.removeItem("sortByOption");
    }, []);

    const onOutsideClick = () => {
        setToggleArrow(false);
    };
    const ref = useOutsideClickHandler(onOutsideClick);

    const closeCalendar = () => {
        setShowCalendar(false);
        setDateRange([null, null])
    };

    const targetSection = () => {
        setDateType(false);
        setToggleArrow(!toggleArrow);
    };

    const dateTypeSelect = () => {
        setDateType(!dateType);
        setToggleArrow(false);
    };

    const toggleCalendar = () => {
        setShowCalendar(true);
    };

    const toggleAddRow = () => {
        setFormValues([...formValues, initialInputValuesAddRow(collectionType)]);
    };

    const handleUserChange = (section) => {
        dispatch(searchTypeAction(section || collectionType));
        setShowError(false);
        setCollectionType(section);
        setToggleArrow(false);
        setInputsData(initialInputValues(section));
        setFormValues([])
    };

    const resetAllFields = () => {
        setSearchType('ASCI');
        setCollectionType('Documents');
        setDateRange([null, null]);
        setShowError(false);
        setDateType(false);
        setToggleArrow(false);
        setShowCalendar(false);
        setInputsData(initialInputValues(collectionType));
        setFormValues([]);
        dispatch(resetStore());
    };

    return (
        <form className='home_form'
              onSubmit={searchData}
              onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                      searchData(event)
                  }
              }}>
            <div className='home_form-item'>
                <label>
                    <span>{translation("Search in")}</span>
                    <div className="switch">
                        <input
                            type="radio"
                            id="contactChoice1"
                            value="ASCI"
                            checked={searchType === 'ASCI'}
                            onChange={() => setSearchType('ASCI')}
                            className='radio-button'
                        />
                        <label htmlFor="contactChoice1" className="switch__label-left">ASCI</label>
                        <input
                            type="radio"
                            id="contactChoice2"
                            value="ASCI NUCLEUS"
                            checked={searchType === 'ASCINUCLEUS'}
                            onChange={() => setSearchType('ASCINUCLEUS')}
                            className='radio-button'
                        />
                        <label htmlFor="contactChoice2" className="switch__label-right">ASCI NUCLEUS</label>
                        <div className="switch__indicator"/>
                    </div>
                </label>
            </div>
            <div className='home_form-item'>
                <span>{translation("Collections")}</span>
                <div className='custom-select' ref={ref}>
                    <div className="user_container" onClick={targetSection}>
                        <p className='dropdown-selected-bold'>{collectionType}</p>
                        <img
                            src={ArrowDownSVG}
                            className={toggleArrow ? 'select-close' : 'select-open'} alt='arrow-icon'
                        />
                    </div>
                    <div className={toggleArrow ? 'drop-down-section section-open' : 'drop-down-section'}>
                        {
                            collectionsArr.map((item, i) => {
                                return (
                                    <p
                                        key={i}
                                        onClick={() => handleUserChange(item)}
                                        className="drop-down-section_button">
                                        {item}
                                    </p>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='home_form-item'>
                <div className="collections-grey-underline"></div>
            </div>
            <Collections collection={collectionType} inputsData={inputsData} setInputsData={setInputsData}
                         option={option} options={options} setOption={setOption}/>
            {error &&
            <p style={{color: 'red', marginBottom: "10px", textAlign: "center"}}>{ErrorMessages(collectionType)}</p>}
            <>
                {
                    showCalendar && (
                        <Calendar
                            closeCalendar={closeCalendar}
                            dateRange={dateRange}
                            dateType={dateType}
                            setDateRange={setDateRange}
                            dateTypeSelect={dateTypeSelect}
                        />
                    )
                }

                {
                    formValues?.length ? formValues.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <AddRows
                                    collectionType={collectionType}
                                    setVal={setFormValues}
                                    val={formValues}
                                    item={item}
                                    index={index}
                                />
                            </React.Fragment>
                        )
                    }) : ""
                }
            </>
            <div className='home_form-buttons'>
                <button className='home_form-buttons_item' type='button' onClick={toggleAddRow}>
                    {translation('Add Row')}
                </button>
                {
                    !showCalendar &&
                    <button className='home_form-buttons_item' type='button' onClick={toggleCalendar}>
                        {translation('Add Date Range')}
                    </button>
                }
            </div>
            <div className='home_form-buttons--search'>
                <button className='home_result-buttons--clear' type='button'
                        onClick={resetAllFields}>{translation("CLEAR")}</button>
                <button className='home_result-buttons--search' type='button'
                        onClick={searchData}>{translation("SEARCH")}</button>
            </div>
        </form>
    );
};

export default HomeForm;