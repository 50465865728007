import React, {useState} from 'react';
import whiteCloseIcon from "../../assets/images/whiteCloseIcon.png";
import ArrowDownSVG from "../../assets/images/arrowDown.svg";
import AddRowsCollection from "../collections/AddRowsCollection";
import useOutsideClickHandler from "../../helpers/custom-hooks/outSideClick";
import {detectSearchTypeUrl, query} from "../../helpers";

const SearchAddRows = ({collectionType, setVal, val, index, item}) => {
    const [dropOptions] = useState(['and', 'or', 'not']);
    const [toggleDropDown, setToggleDropDown] = useState(false);
    const newFormValues = [...val];

    let modifiedString = item.articleKey?.charAt(0).toUpperCase() + item.articleKey?.slice(1);
    if (modifiedString === "All_fields" || modifiedString === "Document_type") {
        modifiedString = modifiedString?.replace("_", " ")
    }

    const [options] = useState([
        'All Fields',
        'Name',
        'Country',
        'Doi',
        'Keyword',
        'Document Type'
    ]);

    const defaultAddRowsOption = () => {
        switch (detectSearchTypeUrl(query())) {
            case "Documents":
                return modifiedString;
            case "Journals":
                return item.ecoom_category;
        }
    };

    const [option, setOption] = useState(defaultAddRowsOption() || options[0]);

    const onOutsideClick = () => {
        setToggleDropDown(false);
    };
    const ref = useOutsideClickHandler(onOutsideClick);

    const handleChangeRow = (e, item) => {
        setToggleDropDown(false);
        newFormValues[index]['dropOptions'] = item;
        setVal(newFormValues);
    };

    const handleChange = (e, i) => {
        if (newFormValues[i]) {
            newFormValues[i][e.target.name] = e.target.value;
        } else {
            newFormValues[e.target.name] = e.target.value;
        }
        setVal(newFormValues);
    };
    const handleDelete = (e) => {
        e.stopPropagation();
        const deleted = newFormValues.filter((del) => del.name !== item.name);
        setVal(deleted)
    };

    return (
        <div className='created-rows'>
            <div className='created-rows_item'>
                <button className='delete-row' type='button' onClick={handleDelete}>
                    <img src={whiteCloseIcon} alt="close-icon"/>
                </button>
                <div className='created-rows_item--selects add-search-rows'>
                    <div className='w-15 drop-options-block'>
                        <div className='home_form-new-row'>
                            <div className='home_form-item home_form-width-auto'>
                                <div className='custom-select' ref={ref}>
                                    <div className="user_container home_form-new-row-line-and-or-not"
                                         onClick={() => setToggleDropDown(!toggleDropDown)}>
                                        <p className='dropdown-selected'>{item.dropOptions}</p>
                                        <img
                                            src={ArrowDownSVG}
                                            className={toggleDropDown ? 'select-close' : 'select-open'} alt='arrow-icon'
                                        />
                                    </div>
                                    <div
                                        className={toggleDropDown ? 'drop-down-section section-open' : 'drop-down-section'}>
                                        {
                                            dropOptions.map((item, i) => {
                                                return (
                                                    <p className='drop-down-section_button'
                                                       key={i}
                                                       onClick={(e) => handleChangeRow(e, item)}
                                                    >
                                                        {item}
                                                    </p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AddRowsCollection collection={collectionType} handleChangeRows={handleChange} item={item} val={val} index={index} setVal={setVal} option={option} options={options} setOption={setOption}/>
                </div>
            </div>
        </div>
    );
};

export default SearchAddRows;