import React, {useEffect, useState} from 'react';
import {dispatch, singlePageSimilarItems} from '../../../helpers';
import {ReactComponent as ArrowSVG} from '../../../assets/images/icons/arrow.svg';
import {setSinglePageOffset} from "../../../redux/slices/global/globalSlice";

const Pagination = ({id, resultsArray}) => {
    const itemsPerPage = 10;
    const total = (resultsArray?.total) / 10;
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        dispatch(setSinglePageOffset(currentPage))
    }, [currentPage]);

    const handlePageChangeNext = () => {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        singlePageSimilarItems(id, nextPage, itemsPerPage);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handlePageChangePrev = () => {
        const prevPage = currentPage - 1;
        setCurrentPage(prevPage);
        singlePageSimilarItems(id, prevPage, itemsPerPage);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <nav className="content_pagination custom_pagination">
            <ul className="pagination">
                {currentPage > 0 && (
                    <li className="page-item prev" onClick={handlePageChangePrev}>
                        <a className="page-link">
                            <ArrowSVG/>
                        </a>
                    </li>
                )}

                <li className="page-item active">
                    <a className="page-link">
                        {currentPage + 1}
                    </a>
                </li>
                <li className="page-item">
                    <span>of</span>
                </li>
                <li className="page-item">
                    <a className="page-link">{Math.ceil(total)}</a>
                </li>
                {
                    currentPage + 1 < Math.ceil(total) &&
                    <li className="page-item next" onClick={handlePageChangeNext}>
                        <a className="page-link">
                            <ArrowSVG/>
                        </a>
                    </li>

                }
            </ul>
        </nav>
    );
};

export default Pagination;