import React from 'react';
import i18next from "i18next";
import {store} from './redux/store';
import {I18nextProvider} from "react-i18next";
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';

import App from './App';

import './assets/styles/index.scss';
import 'rsuite/dist/rsuite.min.css';
import './components/date-range/dateRange.scss';
import './components/error-popup/Error.scss';
import ErrorBoundary from "./ErrorBoundary";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <Provider store={store}>
                <ErrorBoundary>
                    <App/>
                </ErrorBoundary>
            </Provider>
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
