import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {dispatch, orderBySelected, prepareParams, query, requestKeysUpdate, translation} from "../../../../helpers";
import ArrowDownSVG from "../../../../assets/images/arrowDown.svg";
import {
    globalSelector,
    setCategoriesFilterAuthor,
    setLoader,
    setNamesFiltersAuthor,
    setORGFiltersAuthor,
    setSearchDataAuthor
} from "../../../../redux/slices/global/globalSlice";
import {
    searchCategoriesFiltersAuthorApi,
    searchDataAuthorApi,
    searchNamesFiltersAuthorApi,
    searchORGFiltersAuthorApi
} from "../../../../helpers/apis";

const Author = ({setCheckedValues}) => {
    const [toggleArrow, setToggleArrow] = useState([]);
    const [showSeeAllAuthorName, setShowSeeAllAuthorName] = useState(false);
    const [showSeeAllASCICategories, setShowSeeAllASCICategories] = useState(false);
    const [showSeeAllOrganizations, setShowSeeAllOrganizations] = useState(false);
    const {orgFiltersAuthor, categoriesFilterAuthor, namesFilterAuthor} = useSelector(globalSelector);
    const checkBoxesLocalStorage = JSON.parse(localStorage.getItem("CheckBoxes")) || [];
    const checkboxesArr = JSON.parse(localStorage.getItem("searchDataApiArr"));
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));
    const history = useHistory();

    const FilterItemEnum = {
        AuthorName: "AuthorName",
        ASCICategories: "ASCICategories",
        Organizations: "Organizations",
    };

    const mergedArr = searchObj.addedRows?.length ?
        [prepareParams(searchObj.inputsData, requestKeysUpdate)].concat(searchObj.addedRows) :
        [prepareParams(searchObj.inputsData, requestKeysUpdate)];

    const searchDataApiAuthorBody = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
        "offset": 0,
        "limit": 10,
    };

    if(localStorage.getItem("sortByOption")){
        searchDataApiAuthorBody["order_by"] = orderBySelected(localStorage.getItem("sortByOption"))
    }

    const FiltersBodyObj = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
    };

    useEffect(() => {
        if (history.action === "POP") {

            if (checkboxesArr?.ecoom_categories.length) {
                FiltersBodyObj["ecoom_categories"] = checkboxesArr.ecoom_categories;
                searchDataApiAuthorBody["ecoom_categories"] = checkboxesArr.ecoom_categories
            }

            if (checkboxesArr?.organizations.length) {
                FiltersBodyObj["organizations"] = checkboxesArr.organizations;
                searchDataApiAuthorBody["organizations"] = checkboxesArr.organizations
            }

            if (checkboxesArr?.ids.length) {
                FiltersBodyObj["names"] = checkboxesArr.ids;
                searchDataApiAuthorBody["names"] = checkboxesArr.ids
            }

            searchDataAuthorApi(searchDataApiAuthorBody)
                .then((data) => {

                    searchCategoriesFiltersAuthorApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setCategoriesFilterAuthor(data)));

                    searchORGFiltersAuthorApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setORGFiltersAuthor(data)));

                    searchNamesFiltersAuthorApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setNamesFiltersAuthor(data)));

                    dispatch(setSearchDataAuthor(data))
                })
                .finally(() => dispatch(setLoader(false)));
        }

    }, [history]);

    const searchDataApiArr = {
        ecoom_categories: [],
        organizations: [],
        ids: []
    };


    const handleCheckboxChange = (id, type) => {
        const updatedCheckBoxes = [...checkBoxesLocalStorage];
        const index = updatedCheckBoxes.findIndex((checkbox) => checkbox.id === id);

        if (index >= 0) {
            updatedCheckBoxes.splice(index, 1);
        } else {
            updatedCheckBoxes.push({id, type});
        }

        setCheckedValues([]);

        updatedCheckBoxes.forEach((checkbox) => {
            switch (checkbox.type) {
                case "ASCICategories":
                    searchDataApiArr.ecoom_categories.push(Number(checkbox.id));
                    break;
                case "Organizations":
                    searchDataApiArr.organizations.push(Number(checkbox.id));
                    break;
                case "AuthorName":
                    searchDataApiArr.ids.push(Number(checkbox.id));
                    break;
                default:
                    break;
            }
        });

        if (searchDataApiArr.ecoom_categories.length) {
            FiltersBodyObj["ecoom_categories"] = searchDataApiArr.ecoom_categories;
            searchDataApiAuthorBody["ecoom_categories"] = searchDataApiArr.ecoom_categories
        }

        if (searchDataApiArr.organizations.length) {
            FiltersBodyObj["organizations"] = searchDataApiArr.organizations;
            searchDataApiAuthorBody["organizations"] = searchDataApiArr.organizations
        }

        if (searchDataApiArr.ids.length) {
            FiltersBodyObj["names"] = searchDataApiArr.ids;
            searchDataApiAuthorBody["names"] = searchDataApiArr.ids
        }

        localStorage.setItem('CheckBoxes', JSON.stringify(updatedCheckBoxes));
        localStorage.setItem('searchDataApiArr', JSON.stringify(searchDataApiArr));

        dispatch(setLoader(true));
        searchDataAuthorApi(searchDataApiAuthorBody)
            .then((data) => {

                if (type !== "ASCICategories") {
                    searchCategoriesFiltersAuthorApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setCategoriesFilterAuthor(data)));
                }

                if (type !== "Organizations") {
                    searchORGFiltersAuthorApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setORGFiltersAuthor(data)));
                }

                if (type !== "AuthorName") {
                    searchNamesFiltersAuthorApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setNamesFiltersAuthor(data)));
                }

                dispatch(setSearchDataAuthor(data))
            })
            .finally(() => dispatch(setLoader(false)));
    };

    const seeAll = (e, filter) => {
        e.preventDefault();
        switch (filter) {
            case "AuthorName":
                setShowSeeAllAuthorName(true);
                break;
            case "ASCICategories":
                setShowSeeAllASCICategories(true);
                break;
            case "Organizations":
                setShowSeeAllOrganizations(true);
                break;
            default:
                return null
        }
    };

    const targetSection = (id) => {
        setToggleArrow([...toggleArrow, id]);
        if (toggleArrow.includes(id)) {
            const newArray = toggleArrow.filter(item => item !== id);
            setToggleArrow(newArray);
        }
    };

    const authorNamesFilter = namesFilterAuthor.length && !showSeeAllAuthorName ? namesFilterAuthor.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.first_name || item.last_name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.AuthorName)}/>
                            <label htmlFor={item.id} title={item.name}>{item.first_name} {item.last_name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    }) : namesFilterAuthor.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.first_name || item.last_name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.AuthorName)}/>
                            <label htmlFor={item.id} title={item.name}>{item.first_name} {item.last_name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    });

    const categoriesFilter = categoriesFilterAuthor.length && !showSeeAllASCICategories ? categoriesFilterAuthor.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ASCICategories)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    }) : categoriesFilterAuthor.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ASCICategories)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    });

    const orgFiltersData = orgFiltersAuthor.length && !showSeeAllOrganizations ? orgFiltersAuthor.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.Organizations)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    }) : orgFiltersAuthor.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.Organizations)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    });

    return (
        <form className='filter-form home_form'>
            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.AuthorName)}>
                        <p className='dropdown-selected-bold'>{translation('Author Name')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.AuthorName) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.AuthorName) ?
                            'section-open' : !namesFilterAuthor.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.AuthorName) ? "100%" : ""}}>
                        {authorNamesFilter}
                        {
                            !namesFilterAuthor.length ? <p className="no-data-filters">{translation("No data")}</p> : ""
                        }

                        {namesFilterAuthor.length > 5 && !showSeeAllAuthorName ?
                            <button className='link-primary' onClick={(e) => seeAll(e, FilterItemEnum.AuthorName)}>See
                                All</button> : ""}
                    </div>
                </div>
            </div>
            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.Organizations)}>
                        <p className='dropdown-selected-bold'>{translation('Organizations')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Organizations) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Organizations) ? 'section-open' : !orgFiltersAuthor.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Organizations) ? "100%" : ""}}>
                        {orgFiltersData}
                        {
                            !orgFiltersAuthor.length ? <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {orgFiltersAuthor.length > 5 && !showSeeAllOrganizations ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.Organizations)}>See All</button> : ""}
                    </div>
                </div>
            </div>
            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.ASCICategories)}>
                        <p className='dropdown-selected-bold'>{translation('ASCI Categories')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? 'section-open' : !categoriesFilterAuthor.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? "100%" : ""}}>
                        {categoriesFilter}
                        {
                            !categoriesFilterAuthor.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {categoriesFilterAuthor.length > 5 && !showSeeAllASCICategories ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.ASCICategories)}>See All</button> : ""}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Author;