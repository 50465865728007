import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {ReactComponent as ArrowSVG} from "../../../assets/images/icons/arrow.svg";
import ArrowDownSVG from "../../../assets/images/arrowDown.svg";
import ArticlesSimilar from "../components/Authors/Articles";
import {exportAsCsvSinglePage, journalSinglePageApi, translation} from "../../../helpers";
import JournalsCitations from "../components/Citations/Journals";
import ContentJournal from "../components/Journals/ContentJournal";
import {useSelector} from "react-redux";
import {globalSelector} from "../../../redux/slices/global/globalSlice";
import Pagination from "../components/SinglePagePagination";

const ItemJournal = () => {
    const [exportResultsArr] = useState(['Export', 'Export_01', 'Export_02', 'Export_03']);
    const [exportResultsOption, setExportResultsOption] = useState(exportResultsArr[0]);
    const [active, setActive] = useState(null);
    const {journalArticleById, searchObj, singlePageOffset} = useSelector(globalSelector);
    const history = useHistory();
    const {id} = useParams();

    const backToSearch = () => {
        if (history.action === "POP") {
            history.push('/')
        } else {
            history.goBack()
        }
    };

    const ContentFilterItemEnum = {
        ExportResults: 0,
        PageSize: 1,
        SortBy: 2,
        Appearance: 3
    };

    const targetSection = (id) => {
        if (active === id) {
            setActive(null);
        } else {
            setActive(id);
        }
    };

    useEffect(() => {
        journalSinglePageApi(id)
    }, [id]);

    return (
        <main className='content_item_container'>
            <section className='content container'>

                <div className='content_filter'>
                    <div className="form-row tb--align-center">

                        <div className="col-auto tb--flex flex-gap-15 tb--align-center">
                            <button onClick={backToSearch} className='back-to-link'><ArrowSVG/> Back to the Search
                            </button>
                            {/*<button className="btn-info" type="button">Full text</button>*/}
                            {/*<button className="btn-education btn-info" type="button"><EducationSVG/></button>*/}
                        </div>
                        <div className='col-auto ml-auto tb--flex flex-gap-15 tb--align-center'>
                            <div className='content_filter-item'>
                                <div className='custom-select'>
                                    <div className="user_container"
                                         onClick={() => targetSection(ContentFilterItemEnum.ExportResults)}>
                                        <p className='dropdown-selected-bold'>{exportResultsOption}</p>
                                        <img src={ArrowDownSVG}
                                             className={active === ContentFilterItemEnum.ExportResults ? 'select-close' : 'select-open'}
                                             alt='arrow-icon'/>
                                    </div>
                                    <div
                                        className={`drop-down-section scroller ${active === ContentFilterItemEnum.ExportResults ? 'section-open' : ''}`}>
                                        <p onClick={() => {
                                            setActive(null);
                                            exportAsCsvSinglePage(Number(id))
                                        }}
                                           className="drop-down-section_button">
                                            {translation(" Export as csv")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="form-row content-row mb-20">
                    <div className="col content-col">
                        <ContentJournal/>
                    </div>

                    <div className="col citations-col">
                        <JournalsCitations/>
                    </div>
                </div>

                <div className='info_list'>
                    {
                        Object.keys(journalArticleById)?.length ?
                            <>
                                <div className='content_filter'>
                                    <div className="form-row tb--align-center">
                                        <div className="col-auto tb--flex flex-gap-20">
                                            <div className='content_publication-text'>
                                                <p>Publications</p>
                                                <p>{journalArticleById?.total} publication from
                                                    the {searchObj.type || "ASCI"}</p>
                                            </div>
                                        </div>
                                        {
                                            journalArticleById?.total ?
                                                <div className='col-auto ml-auto tb--flex flex-gap-20 tb--align-center'>
                                                    <p className='content_showing-text'>
                                                        {
                                                            (singlePageOffset + 1) * 10 > journalArticleById?.total ? `Showing ${journalArticleById?.total} of ${journalArticleById?.total}` :
                                                                `Showing ${(singlePageOffset + 1) * 10} of ${journalArticleById?.total}`
                                                        }
                                                    </p>
                                                </div> : ""
                                        }
                                    </div>
                                </div>
                                <div className='info_list mb-20'>
                                    <ArticlesSimilar singlePageAuthorSimilar={journalArticleById?.results}/>
                                </div>
                                <Pagination id={id} resultsArray={journalArticleById}/>
                            </> : ""
                    }
                </div>
            </section>
        </main>
    );
};

export default ItemJournal;