import React from 'react';
import {Link} from "react-router-dom";
import {detectSearchTypeUrl, dispatch, query, translation} from "../../../../helpers";
import {
    acknowledgmentFilterArticlesApi,
    armJournalArticlesApi,
    authorFilterArticlesApi,
    categoriesFilterArticlesApi,
    docTypeFilterArticlesApi,
    fundingFilterArticlesApi,
    languageFilterArticlesApi,
    orgFilterArticlesApi,
    searchDataArticlesApi,
    volumeFilterArticlesApi
} from "../../../../helpers/apis";
import {
    setAcknowledgmentFilterArticles,
    setArmJournalFilterArticles,
    setAuthorFilterArticles,
    setCategoriesFilterArticles,
    setDocTypeFilterArticles,
    setFundingFilterArticles,
    setLanguageFilterArticles,
    setLoader,
    setORGFiltersArticles,
    setSearchDataArticles,
    setVolumeFilterArticles
} from "../../../../redux/slices/global/globalSlice";

const CitedWork = ({searchResultCitedWork, handleCheckboxChange, checkedValues}) => {
    const {type} = JSON.parse(localStorage.getItem("searchObj"));

    return searchResultCitedWork.map((item, index) => {
     const ids = item?.article_ids.map(item => Number(item));

        return (
            <article className='info_item' key={index}>
                <label className='custom-input-checkbox'>
                    <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(item.id)}
                        checked={checkedValues.includes(Number(item.id))}
                    />
                    <span className="checkmark"></span></label>
                <span className='info_number'>{index + 1}</span>
                <h3 className='info_title'>
                    <a>{item?.cited_article_name}</a>
                    {/*<mark>Yerevan</mark>*/}
                </h3>
                <span className="line"></span>
                <div className='info_block'>
                    <p className='info_date'>
                        {item?.cited_publ_year} | {item?.cited_arm_journal_name}&nbsp;{item?.cited_volume}&nbsp;
                        ({item?.cited_am_j_issue})
                        pp {item?.page_begin && item?.page_end ? item?.page_begin + "-" + item?.page_end :
                        item.page_begin}
                    </p>
                </div>
                <span className="line"></span>
                <div className="form-row">
                    <div className="col-auto">
                        <Link to={`/search?ids=${item?.article_ids?.join(',')}&searchType=${type}&offset=${0}&limit=${10}`} className="btn-info"
                              onClick={() => {

                                  dispatch(setLoader(true));

                                  searchDataArticlesApi({
                                      "version": type === 'ASCI' ? 1 : 2,
                                      "ids": ids,
                                      "offset": 0,
                                      "limit": 10,
                                  })
                                      .then((data) => {
                                          dispatch(setSearchDataArticles(data));
                                          localStorage.setItem("lang", data.language);

                                          volumeFilterArticlesApi({
                                              "version": type === 'ASCI' ? 1 : 2,
                                              "ids": ids
                                          }, {language: data.language}).then((data) => dispatch(setVolumeFilterArticles(data)));

                                          docTypeFilterArticlesApi({
                                              "version": type === 'ASCI' ? 1 : 2,
                                              "ids": ids
                                          }, {language: data.language}).then((data) => dispatch(setDocTypeFilterArticles(data)));

                                          armJournalArticlesApi({
                                              "version": type === 'ASCI' ? 1 : 2,
                                              "ids": ids
                                          }, {language: data.language}).then((data) => dispatch(setArmJournalFilterArticles(data)));

                                          categoriesFilterArticlesApi({
                                              "version": type === 'ASCI' ? 1 : 2,
                                              "ids": ids
                                          }, {language: data.language}).then((data) => dispatch(setCategoriesFilterArticles(data)));

                                          authorFilterArticlesApi({
                                              "version": type === 'ASCI' ? 1 : 2,
                                              "ids": ids
                                          }, {language: data.language}).then((data) => dispatch(setAuthorFilterArticles(data)));

                                          orgFilterArticlesApi({
                                              "version": type === 'ASCI' ? 1 : 2,
                                              "ids": ids
                                          }, {language: data.language}).then((data) => dispatch(setORGFiltersArticles(data)));

                                          languageFilterArticlesApi({
                                              "version": type === 'ASCI' ? 1 : 2,
                                              "ids": ids
                                          }, {language: data.language}).then((data) => dispatch(setLanguageFilterArticles(data)));

                                          acknowledgmentFilterArticlesApi({
                                              "version": type === 'ASCI' ? 1 : 2,
                                              "ids": ids
                                          }, {language: data.language}).then((data) => dispatch(setAcknowledgmentFilterArticles(data)));

                                          fundingFilterArticlesApi({
                                              "version": type === 'ASCI' ? 1 : 2,
                                              "ids": ids
                                          }, {language: data.language}).then((data) => dispatch(setFundingFilterArticles(data)));
                                      })

                                      .finally(() => dispatch(setLoader(false)));

                                  const { offset, limit, searchType } = query();

                                  const result = {
                                      offset,
                                      limit,
                                      searchType,
                                      ids
                                  };

                                  localStorage.setItem("searchObj", JSON.stringify({
                                      type: query().searchType,
                                      collections: detectSearchTypeUrl({ids}),
                                      dateRange : "",
                                      inputsData: result,
                                      addedRows: []
                                  }));
                              }}>
                            <span>{item?.article_ids?.length}</span>
                            {translation('Citing Articles')}
                        </Link>
                    </div>
                </div>
            </article>
        );
    });

};

export default CitedWork;