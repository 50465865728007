import React from 'react';
import {useSelector} from "react-redux";
import {globalSelector} from "../../../../redux/slices/global/globalSlice";

const JournalsCitations = () => {
    const {journalCountById} = useSelector(globalSelector);

    return (
        <aside className="card h-100">
            <div className="card_body">
                <div className='card_item'>
                    <div className='tb--flex tb--align-start tb--justify-between'>
                        <div className='tb--tal'>
                            <p className='number'>{journalCountById[0]?.impact_factor}</p>
                            <p className='name'>H-Index</p>
                        </div>

                        <div className='tb--tar'>
                            <p className='number'>{journalCountById[0]?.total_citations_sum}</p>
                            <p className='name'>Citations</p>
                        </div>
                    </div>
                </div>
                <div className='card_item'>
                    <div className='tb--flex tb--align-start tb--justify-between'>
                        <div className='tb--tal'>
                            <p className='number'>{journalCountById[0]?.total_articles_sum}</p>
                            <p className='name'>Number of Publications</p>
                        </div>

                        <div className='tb--tar'>
                            <p className='number'>{journalCountById[0]?.total_references_sum}</p>
                            <p className='name'>References</p>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default JournalsCitations;