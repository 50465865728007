import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    globalSelector,
    setLoader,
    setOrgCategoriesFilter,
    setOrgVolumeFilter,
    setSearchORGData
} from "../../../../redux/slices/global/globalSlice";
import {dispatch, orderBySelected, prepareParams, query, requestKeysUpdate, translation} from "../../../../helpers";
import {
    organizationsCategoriesFilterApi,
    organizationsVolumeFilterApi,
    searchDataOrganizationsApi
} from "../../../../helpers/apis";
import ArrowDownSVG from "../../../../assets/images/arrowDown.svg";

const Organization = ({setCheckedValues}) => {
    const [toggleArrow, setToggleArrow] = useState([]);
    const [showSeeAllPublicationYears, setShowSeeAllPublicationYears] = useState(false);
    const [showSeeAllASCICategories, setShowSeeAllASCICategories] = useState(false);
    const {orgCategoriesFilter, orgVolumeFilter} = useSelector(globalSelector);
    const checkBoxesLocalStorage = JSON.parse(localStorage.getItem("CheckBoxes")) || [];
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));
    const checkboxesArr = JSON.parse(localStorage.getItem("searchDataApiArr"));
    const history = useHistory();

    const FilterItemEnum = {
        PublicationYears: "PublicationYears",
        ASCICategories: "ASCICategories",
    };

    const mergedArr = searchObj.addedRows?.length ?
        [prepareParams(searchObj.inputsData, requestKeysUpdate)].concat(searchObj.addedRows) :
        [prepareParams(searchObj.inputsData, requestKeysUpdate)];

    const searchDataApiAuthorBody = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
        "offset": 0,
        "limit": 10,
    };

    if(localStorage.getItem("sortByOption")){
        searchDataApiAuthorBody["order_by"] = orderBySelected(localStorage.getItem("sortByOption"))
    }

    const searchDataApiArr = {
        ecoom_categories: [],
        volumes: []
    };

    const FiltersBodyObj = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
    };

    useEffect(() => {
        if (history.action === "POP") {

            if (checkboxesArr?.ecoom_categories.length) {
                FiltersBodyObj["ecoom_categories"] = checkboxesArr.ecoom_categories;
                searchDataApiAuthorBody["ecoom_categories"] = checkboxesArr.ecoom_categories
            }

            if (checkboxesArr?.volumes.length) {
                FiltersBodyObj["volumes"] = checkboxesArr.volumes;
                searchDataApiAuthorBody["volumes"] = checkboxesArr.volumes
            }

            searchDataOrganizationsApi(searchDataApiAuthorBody)
                .then((data) => {

                    organizationsCategoriesFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setOrgCategoriesFilter(data)));

                    organizationsVolumeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setOrgVolumeFilter(data)));

                    dispatch(setSearchORGData(data))
                })
                .finally(() => dispatch(setLoader(false)));
        }
    }, [history]);

    const handleCheckboxChange = (id, type) => {
        const updatedCheckBoxes = [...checkBoxesLocalStorage];
        const index = updatedCheckBoxes.findIndex((checkbox) => checkbox.id === id);

        if (index >= 0) {
            updatedCheckBoxes.splice(index, 1);
        } else {
            updatedCheckBoxes.push({id, type});
        }

        setCheckedValues([]);

        updatedCheckBoxes.forEach((checkbox) => {
            switch (checkbox.type) {
                case "ASCICategories":
                    searchDataApiArr.ecoom_categories.push(Number(checkbox.id));
                    break;
                case "PublicationYears":
                    searchDataApiArr.volumes.push(Number(checkbox.id));
                    break;
                default:
                    break;
            }
        });

        if (searchDataApiArr.ecoom_categories.length) {
            FiltersBodyObj["ecoom_categories"] = searchDataApiArr.ecoom_categories;
            searchDataApiAuthorBody["ecoom_categories"] = searchDataApiArr.ecoom_categories
        }

        if (searchDataApiArr.volumes.length) {
            FiltersBodyObj["volumes"] = searchDataApiArr.volumes;
            searchDataApiAuthorBody["volumes"] = searchDataApiArr.volumes
        }

        localStorage.setItem('CheckBoxes', JSON.stringify(updatedCheckBoxes));
        localStorage.setItem('searchDataApiArr', JSON.stringify(searchDataApiArr));

        dispatch(setLoader(true));
        searchDataOrganizationsApi(searchDataApiAuthorBody)
            .then((data) => {

                if (type !== "ASCICategories") {
                    organizationsCategoriesFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setOrgCategoriesFilter(data)));
                }

                if (type !== "PublicationYears") {
                    organizationsVolumeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setOrgVolumeFilter(data)));
                }

                dispatch(setSearchORGData(data))
            })
            .finally(() => dispatch(setLoader(false)));
    };

    const seeAll = (e, filter) => {
        e.preventDefault();
        switch (filter) {
            case "ASCICategories":
                setShowSeeAllASCICategories(true);
                break;
            case "PublicationYears":
                setShowSeeAllPublicationYears(true);
                break;
            default:
                return null
        }
    };

    const targetSection = (id) => {
        setToggleArrow([...toggleArrow, id]);
        if (toggleArrow.includes(id)) {
            const newArray = toggleArrow.filter(item => item !== id);
            setToggleArrow(newArray);
        }
    };

    const categoriesFilter = orgCategoriesFilter.length && !showSeeAllASCICategories ? orgCategoriesFilter.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ASCICategories)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    }) : orgCategoriesFilter.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ASCICategories)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    });

    const publicationYears = orgVolumeFilter.length && !setShowSeeAllPublicationYears ? orgVolumeFilter.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.year}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.year)}
                           onChange={() => handleCheckboxChange(item.year, FilterItemEnum.PublicationYears)}/>
                    <label htmlFor={item.year} title={item.year}>{item.year}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    }) : orgVolumeFilter.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.year}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.year)}
                           onChange={() => handleCheckboxChange(item.year, FilterItemEnum.PublicationYears)}/>
                    <label htmlFor={item.year} title={item.year}>{item.year}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    });

    return (
        <form className='filter-form home_form'>
            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.PublicationYears)}>
                        <p className='dropdown-selected-bold'>{translation('Publication Years')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? 'section-open' : !orgVolumeFilter.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? "100%" : ""}}>
                        {publicationYears}
                        {
                            !orgVolumeFilter.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {orgVolumeFilter.length > 5 && !showSeeAllPublicationYears ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.PublicationYears)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.ASCICategories)}>
                        <p className='dropdown-selected-bold'>{translation('ASCICategories')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? 'section-open' : !orgCategoriesFilter.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? "100%" : ""}}>
                        {categoriesFilter}
                        {
                            !orgCategoriesFilter.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {orgCategoriesFilter.length > 5 && !showSeeAllASCICategories ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.ASCICategories)}>See All</button> : ""}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Organization;