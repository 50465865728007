import { useEffect, useRef } from 'react';

function useOutsideClickHandler(onOutsideClick) {
    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onOutsideClick]);

    return ref;
}

export default useOutsideClickHandler;