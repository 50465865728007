import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {dispatch, orderBySelected, prepareParams, query, requestKeysUpdate, translation} from "../../../../helpers";
import ArrowDownSVG from "../../../../assets/images/arrowDown.svg";
import {useSelector} from "react-redux";
import {
    globalSelector,
    setAcknowledgmentFilterArticles,
    setArmJournalFilterArticles,
    setAuthorFilterArticles,
    setCategoriesFilterArticles,
    setDocTypeFilterArticles,
    setFundingFilterArticles,
    setLanguageFilterArticles,
    setLoader,
    setORGFiltersArticles,
    setSearchDataArticles,
    setVolumeFilterArticles,
} from "../../../../redux/slices/global/globalSlice";
import {
    acknowledgmentFilterArticlesApi,
    armJournalArticlesApi,
    authorFilterArticlesApi,
    categoriesFilterArticlesApi,
    docTypeFilterArticlesApi,
    fundingFilterArticlesApi,
    languageFilterArticlesApi,
    orgFilterArticlesApi,
    searchDataArticlesApi,
    volumeFilterArticlesApi
} from "../../../../helpers/apis";

const Article = ({setCheckedValues}) => {
    const [toggleArrow, setToggleArrow] = useState([]);
    const [showSeeAllPublicationYears, setShowSeeAllPublicationYears] = useState(false);
    const [showSeeAllDocumentTypes, setShowSeeAllDocumentTypes] = useState(false);
    const [showSeeAllArmJournal, setSeeAllArmJournal] = useState(false);
    const [showSeeAllASCICategories, setShowSeeAllASCICategories] = useState(false);
    const [showSeeAllAuthor, setShowSeeAllAuthor] = useState(false);
    const [showSeeAllOrganizations, setShowSeeAllOrganizations] = useState(false);
    const [showSeeAllLanguage, setShowSeeAllLanguage] = useState(false);
    const [showSeeAllFunding, setShowSeeAllFunding] = useState(false);
    const [showSeeAllAcknowledgement, setShowSeeAllAcknowledgement] = useState(false);
    const checkBoxesLocalStorage = JSON.parse(localStorage.getItem("CheckBoxes")) || [];
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));
    const checkboxesArr = JSON.parse(localStorage.getItem("searchDataApiArr"));
    const history = useHistory();

    const {
        volumeFilterArticles,
        doctypeFilterArticles,
        armJournalFilterArticles,
        categoriesFilterArticles,
        authorFilterArticles,
        orgFilterArticles,
        languageFilterArticles,
        acknowledgmentFilterArticles,
        fundingFilterArticles
    } = useSelector(globalSelector);

    const FilterItemEnum = {
        PublicationYears: "PublicationYears",
        DocumentTypes: "DocumentTypes",
        ArmJournal: "ArmJournal",
        ASCICategories: "ASCICategories",
        DocumentAuthors: "DocumentAuthors",
        Organizations: "Organizations",
        Language: "Language",
        Funding: "Funding",
        Acknowledgement: "Acknowledgement"
    };

    const targetSection = (id) => {
        setToggleArrow([...toggleArrow, id]);
        if (toggleArrow.includes(id)) {
            const newArray = toggleArrow.filter(item => item !== id);
            setToggleArrow(newArray);
        }
    };

    const mergedArr = searchObj.addedRows?.length ?
        [prepareParams(searchObj.inputsData, requestKeysUpdate)].concat(searchObj.addedRows) :
        [prepareParams(searchObj.inputsData, requestKeysUpdate)];

    const FiltersBodyObj = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
    };

    const searchDataApiAuthorBody = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
        "offset": 0,
        "limit": 10,
    };

    if (localStorage.getItem("sortByOption")) {
        searchDataApiAuthorBody["order_by"] = orderBySelected(localStorage.getItem("sortByOption"))
    }

    useEffect(() => {
        if (history.action === "POP") {
            if (checkboxesArr?.ecoom_categories.length) {
                FiltersBodyObj["ecoom_categories"] = checkboxesArr.ecoom_categories;
                searchDataApiAuthorBody["ecoom_categories"] = checkboxesArr.ecoom_categories
            }

            if (checkboxesArr?.organizations.length) {
                FiltersBodyObj["organizations"] = checkboxesArr.organizations;
                searchDataApiAuthorBody["organizations"] = checkboxesArr.organizations
            }

            if (checkboxesArr?.authors.length) {
                FiltersBodyObj["authors"] = checkboxesArr.authors;
                searchDataApiAuthorBody["authors"] = checkboxesArr.authors
            }

            if (checkboxesArr?.volumes.length) {
                FiltersBodyObj["volumes"] = checkboxesArr.volumes;
                searchDataApiAuthorBody["volumes"] = checkboxesArr.volumes
            }

            if (checkboxesArr?.document_types.length) {
                FiltersBodyObj["document_types"] = checkboxesArr.document_types;
                searchDataApiAuthorBody["document_types"] = checkboxesArr.document_types
            }

            if (checkboxesArr?.arm_journals.length) {
                FiltersBodyObj["arm_journals"] = checkboxesArr.arm_journals;
                searchDataApiAuthorBody["arm_journals"] = checkboxesArr.arm_journals
            }

            if (checkboxesArr?.acknowledgements.length) {
                FiltersBodyObj["acknowledgements"] = checkboxesArr.acknowledgements;
                searchDataApiAuthorBody["acknowledgements"] = checkboxesArr.acknowledgements
            }

            if (checkboxesArr?.languages.length) {
                FiltersBodyObj["languages"] = checkboxesArr.languages;
                searchDataApiAuthorBody["languages"] = checkboxesArr.languages
            }

            if (checkboxesArr?.fundings.length) {
                FiltersBodyObj["fundings"] = checkboxesArr.fundings;
                searchDataApiAuthorBody["fundings"] = checkboxesArr.fundings
            }

            searchDataArticlesApi(searchDataApiAuthorBody)
                .then((data) => {

                    categoriesFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setCategoriesFilterArticles(data)));

                    orgFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setORGFiltersArticles(data)));

                    authorFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setAuthorFilterArticles(data)));

                    armJournalArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setArmJournalFilterArticles(data)));

                    volumeFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setVolumeFilterArticles(data)));

                    docTypeFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setDocTypeFilterArticles(data)));

                    acknowledgmentFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setAcknowledgmentFilterArticles(data)));

                    languageFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setLanguageFilterArticles(data)));

                    fundingFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setFundingFilterArticles(data)));

                    dispatch(setSearchDataArticles(data))
                })
                .finally(() => dispatch(setLoader(false)));
        }
    }, [history]);

    const searchDataApiArr = {
        volumes: [],
        document_types: [],
        arm_journals: [],
        ecoom_categories: [],
        authors: [],
        organizations: [],
        languages: [],
        acknowledgements: [],
        fundings: [],
    };

    const handleCheckboxChange = (id, type) => {
        const updatedCheckBoxes = [...checkBoxesLocalStorage];
        const index = updatedCheckBoxes.findIndex((checkbox) => checkbox.id === id);

        if (index >= 0) {
            updatedCheckBoxes.splice(index, 1);
        } else {
            updatedCheckBoxes.push({id, type});
        }

        setCheckedValues([]);

        updatedCheckBoxes.forEach((checkbox) => {
            switch (checkbox.type) {
                case FilterItemEnum.PublicationYears:
                    searchDataApiArr.volumes.push(Number(checkbox.id));
                    break;
                case FilterItemEnum.Organizations:
                    searchDataApiArr.organizations.push(Number(checkbox.id));
                    break;
                case FilterItemEnum.DocumentAuthors:
                    searchDataApiArr.authors.push(Number(checkbox.id));
                    break;
                case FilterItemEnum.ASCICategories:
                    searchDataApiArr.ecoom_categories.push(Number(checkbox.id));
                    break;
                case FilterItemEnum.Funding:
                    searchDataApiArr.fundings.push(checkbox.id);
                    break;
                case FilterItemEnum.Language:
                    searchDataApiArr.languages.push(checkbox.id);
                    break;
                case FilterItemEnum.Acknowledgement:
                    searchDataApiArr.acknowledgements.push(checkbox.id);
                    break;
                case FilterItemEnum.ArmJournal:
                    searchDataApiArr.arm_journals.push(Number(checkbox.id));
                    break;
                case FilterItemEnum.DocumentTypes:
                    searchDataApiArr.document_types.push(Number(checkbox.id));
                    break;
                default:
                    break;
            }
        });

        if (searchDataApiArr.ecoom_categories.length) {
            FiltersBodyObj["ecoom_categories"] = searchDataApiArr.ecoom_categories;
            searchDataApiAuthorBody["ecoom_categories"] = searchDataApiArr.ecoom_categories
        }

        if (searchDataApiArr.organizations.length) {
            FiltersBodyObj["organizations"] = searchDataApiArr.organizations;
            searchDataApiAuthorBody["organizations"] = searchDataApiArr.organizations
        }

        if (searchDataApiArr.authors.length) {
            FiltersBodyObj["authors"] = searchDataApiArr.authors;
            searchDataApiAuthorBody["authors"] = searchDataApiArr.authors
        }

        if (searchDataApiArr.volumes.length) {
            FiltersBodyObj["volumes"] = searchDataApiArr.volumes;
            searchDataApiAuthorBody["volumes"] = searchDataApiArr.volumes
        }

        if (searchDataApiArr.document_types.length) {
            FiltersBodyObj["document_types"] = searchDataApiArr.document_types;
            searchDataApiAuthorBody["document_types"] = searchDataApiArr.document_types
        }

        if (searchDataApiArr.arm_journals.length) {
            FiltersBodyObj["arm_journals"] = searchDataApiArr.arm_journals;
            searchDataApiAuthorBody["arm_journals"] = searchDataApiArr.arm_journals
        }

        if (searchDataApiArr.acknowledgements.length) {
            FiltersBodyObj["acknowledgements"] = searchDataApiArr.acknowledgements;
            searchDataApiAuthorBody["acknowledgements"] = searchDataApiArr.acknowledgements
        }

        if (searchDataApiArr.languages.length) {
            FiltersBodyObj["languages"] = searchDataApiArr.languages;
            searchDataApiAuthorBody["languages"] = searchDataApiArr.languages
        }

        if (searchDataApiArr.fundings.length) {
            FiltersBodyObj["fundings"] = searchDataApiArr.fundings;
            searchDataApiAuthorBody["fundings"] = searchDataApiArr.fundings
        }

        localStorage.setItem('CheckBoxes', JSON.stringify(updatedCheckBoxes));
        localStorage.setItem('searchDataApiArr', JSON.stringify(searchDataApiArr));

        if (searchObj.inputsData.ids && searchObj.inputsData.ids.length > 0) {
            delete searchDataApiAuthorBody["query"];
            searchDataApiAuthorBody["ids"] = searchObj.inputsData.ids;
            delete FiltersBodyObj["query"];
            FiltersBodyObj["ids"] = searchObj.inputsData.ids;
        }

        dispatch(setLoader(true));
        searchDataArticlesApi(searchDataApiAuthorBody)
            .then((data) => {

                if (type !== FilterItemEnum.ASCICategories) {
                    categoriesFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setCategoriesFilterArticles(data)))
                }

                if (type !== FilterItemEnum.Organizations) {
                    orgFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setORGFiltersArticles(data)))
                }

                if (type !== FilterItemEnum.DocumentAuthors) {
                    authorFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setAuthorFilterArticles(data)))
                }

                if (type !== FilterItemEnum.ArmJournal) {
                    armJournalArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setArmJournalFilterArticles(data)))
                }

                if (type !== FilterItemEnum.PublicationYears) {
                    volumeFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setVolumeFilterArticles(data)))
                }

                if (type !== FilterItemEnum.DocumentTypes) {
                    docTypeFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setDocTypeFilterArticles(data)))
                }

                if (type !== FilterItemEnum.Acknowledgement) {
                    acknowledgmentFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setAcknowledgmentFilterArticles(data)))
                }

                if (type !== FilterItemEnum.Language) {
                    languageFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setLanguageFilterArticles(data)))
                }

                if (type !== FilterItemEnum.Funding) {
                    fundingFilterArticlesApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setFundingFilterArticles(data)))
                }

                dispatch(setSearchDataArticles(data))
            })
            .finally(() => dispatch(setLoader(false)));
    };

    const seeAll = (e, filter) => {
        e.preventDefault();
        switch (filter) {
            case "PublicationYears":
                setShowSeeAllPublicationYears(true);
                break;
            case "DocumentTypes":
                setShowSeeAllDocumentTypes(true);
                break;
            case "ArmJournal":
                setSeeAllArmJournal(true);
                break;
            case "ASCICategories":
                setShowSeeAllASCICategories(true);
                break;
            case "DocumentAuthors":
                setShowSeeAllAuthor(true);
                break;
            case "Organizations":
                setShowSeeAllOrganizations(true);
                break;
            case "Language":
                setShowSeeAllLanguage(true);
                break;
            case "Funding":
                setShowSeeAllFunding(true);
                break;
            case "Acknowledgement":
                setShowSeeAllAcknowledgement(true);
                break;
            default:
                return null
        }
    };

    const volumeFilter = volumeFilterArticles.length && !showSeeAllPublicationYears ? volumeFilterArticles.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.year}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.year)}
                           onChange={() => handleCheckboxChange(item.year, FilterItemEnum.PublicationYears)}
                    />
                    <label htmlFor={item.year} title={item.year}>{item.year}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    }) : volumeFilterArticles.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.year}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.year)}
                           onChange={() => handleCheckboxChange(item.year, FilterItemEnum.PublicationYears)}
                    />
                    <label htmlFor={item.year} title={item.year}>{item.year}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    });

    const docTypeFilter = doctypeFilterArticles.length && !showSeeAllDocumentTypes ? doctypeFilterArticles.slice(0, 5).map((item, i) => {
        return (
            <>
                {
                    item.name !== null ?
                        <div key={i} className="drop-down-section_button">
                            <p className="custom-checkbox">

                                <>
                                    <input type="checkbox" id={item.id}
                                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                           onChange={() => handleCheckboxChange(item.id, FilterItemEnum.DocumentTypes)}
                                    />
                                    <label htmlFor={item.id}
                                           title={item.name}>
                                        {item.name}
                                    </label>
                                </>
                            </p>
                            <p className='public-text'>{item.count}</p>
                        </div> : ""
                }
            </>

        )
    }) : doctypeFilterArticles.map((item, i) => {
        return (
            <>
                {
                    item.name !== null ?
                        <div key={i} className="drop-down-section_button">
                            <p className="custom-checkbox">

                                <input type="checkbox" id={item.id}
                                       checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                       onChange={() => handleCheckboxChange(item.id, FilterItemEnum.DocumentTypes)}
                                />
                                <label htmlFor={item.id}
                                       title={item.name}>
                                    {item.name}
                                </label>

                            </p>
                            <p className='public-text'>{item.count}</p>
                        </div> : ""
                }
            </>
        )
    });

    const armJournal = armJournalFilterArticles.length && !showSeeAllArmJournal ? armJournalFilterArticles.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.id}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                           onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ArmJournal)}
                    />
                    <label htmlFor={item.id} title={item.name}>{item.name}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    }) : armJournalFilterArticles.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.id}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                           onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ArmJournal)}
                    />
                    <label htmlFor={item.id} title={item.name}>{item.name}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    });

    const categories = categoriesFilterArticles.length && !showSeeAllASCICategories ? categoriesFilterArticles.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.id}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                           onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ASCICategories)}
                    />
                    <label htmlFor={item.id} title={item.name}>{item.name}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    }) : categoriesFilterArticles.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.id}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                           onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ASCICategories)}
                    />
                    <label htmlFor={item.id} title={item.name}>{item.name}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    });

    const documentAuthor = authorFilterArticles.length && !showSeeAllAuthor ? authorFilterArticles.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.id}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                           onChange={() => handleCheckboxChange(item.id, FilterItemEnum.DocumentAuthors)}
                    />
                    <label htmlFor={item.id} title={item.name}>{item.name}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    }) : authorFilterArticles.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.id}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                           onChange={() => handleCheckboxChange(item.id, FilterItemEnum.DocumentAuthors)}
                    />
                    <label htmlFor={item.id} title={item.name}>{item.name}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    });

    const organization = orgFilterArticles.length && !showSeeAllOrganizations ? orgFilterArticles.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.id}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                           onChange={() => handleCheckboxChange(item.id, FilterItemEnum.Organizations)}
                    />
                    <label htmlFor={item.id} title={item.name}>{item.name}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    }) : orgFilterArticles.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.id}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                           onChange={() => handleCheckboxChange(item.id, FilterItemEnum.Organizations)}
                    />
                    <label htmlFor={item.id} title={item.name}>{item.name}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    });

    const language = languageFilterArticles.length && !showSeeAllLanguage ? languageFilterArticles.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.language}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.language)}
                           onChange={() => handleCheckboxChange(item.language, FilterItemEnum.Language)}
                    />
                    <label htmlFor={item.language} title={item.language}>{item.language}</label>
                </p>
            </div>
        )
    }) : languageFilterArticles.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.language}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.language)}
                           onChange={() => handleCheckboxChange(item.language, FilterItemEnum.Language)}
                    />
                    <label htmlFor={item.language} title={item.language}>{item.language}</label>
                </p>
            </div>
        )
    });

    const funding = fundingFilterArticles.length && !showSeeAllFunding ? fundingFilterArticles.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.funding}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.funding)}
                           onChange={() => handleCheckboxChange(item.funding, FilterItemEnum.Funding)}
                    />
                    <label htmlFor={item.funding} title={item.funding}>{item.funding}</label>
                </p>
            </div>
        )
    }) : fundingFilterArticles.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.funding}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.funding)}
                           onChange={() => handleCheckboxChange(item.funding, FilterItemEnum.Funding)}
                    />
                    <label htmlFor={item.funding} title={item.funding}>{item.funding}</label>
                </p>
            </div>
        )
    });

    const acknowledgement = acknowledgmentFilterArticles.length && !showSeeAllAcknowledgement ? acknowledgmentFilterArticles.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.acknowledgement}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.acknowledgement)}
                           onChange={() => handleCheckboxChange(item.acknowledgement, FilterItemEnum.Acknowledgement)}
                    />
                    <label htmlFor={item.acknowledgement} title={item.acknowledgement}>{item.acknowledgement}</label>
                </p>
            </div>
        )
    }) : acknowledgmentFilterArticles.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.acknowledgement}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.acknowledgement)}
                           onChange={() => handleCheckboxChange(item.acknowledgement, FilterItemEnum.Acknowledgement)}
                    />
                    <label htmlFor={item.acknowledgement} title={item.acknowledgement}>{item.acknowledgement}</label>
                </p>
            </div>
        )
    });


    return (
        <form className='filter-form home_form'>
            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.PublicationYears)}>
                        <p className='dropdown-selected-bold'>{translation('Publication Years')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? 'section-open' : ''}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? "100%" : ""}}
                    >
                        {volumeFilter}
                        {
                            !volumeFilterArticles.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {volumeFilterArticles.length > 5 && !showSeeAllPublicationYears ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.PublicationYears)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.DocumentTypes)}>
                        <p className='dropdown-selected-bold'>{translation('Document Types')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocumentTypes) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocumentTypes) ? 'section-open' : ''}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocumentTypes) ? "100%" : ""}}
                    >
                        {docTypeFilter}
                        {
                            !doctypeFilterArticles.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {doctypeFilterArticles.length > 5 && !showSeeAllDocumentTypes ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.DocumentTypes)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.ArmJournal)}>
                        <p className='dropdown-selected-bold'>{translation('Document Source')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ArmJournal) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ArmJournal) ? 'section-open' : ''}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ArmJournal) ? "100%" : ""}}
                    >
                        {armJournal}
                        {
                            !armJournalFilterArticles.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {armJournalFilterArticles.length > 5 && !showSeeAllArmJournal ?
                            <button className='link-primary' onClick={(e) => seeAll(e, FilterItemEnum.ArmJournal)}>See
                                All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.ASCICategories)}>
                        <p className='dropdown-selected-bold'>{translation('ASCI Categories')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? 'section-open' : ''}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? "100%" : ""}}
                    >
                        {categories}
                        {
                            !categoriesFilterArticles.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {categoriesFilterArticles.length > 5 && !showSeeAllASCICategories ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.ASCICategories)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.DocumentAuthors)}>
                        <p className='dropdown-selected-bold'>{translation('Document Authors')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocumentAuthors) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocumentAuthors) ? 'section-open' : ''}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocumentAuthors) ? "100%" : ""}}
                    >
                        {documentAuthor}
                        {
                            !authorFilterArticles.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {authorFilterArticles.length > 5 && !showSeeAllAuthor ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.DocumentAuthors)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.Organizations)}>
                        <p className='dropdown-selected-bold'>{translation('Organizations')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Organizations) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Organizations) ? 'section-open' : ''}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Organizations) ? "100%" : ""}}
                    >
                        {organization}
                        {
                            !orgFilterArticles.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {orgFilterArticles.length > 5 && !showSeeAllOrganizations ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.Organizations)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.Language)}>
                        <p className='dropdown-selected-bold'>{translation('Language')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Language) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Language) ? 'section-open' : ''}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Language) ? "100%" : ""}}
                    >
                        {language}
                        {
                            !languageFilterArticles.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {languageFilterArticles.length > 5 && !showSeeAllLanguage ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.Language)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.Funding)}>
                        <p className='dropdown-selected-bold'>{translation('Funding')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Funding) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Funding) ? 'section-open' : ''}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Funding) ? "100%" : ""}}
                    >
                        {funding}
                        {
                            !fundingFilterArticles.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {fundingFilterArticles.length > 5 && !showSeeAllFunding ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.Funding)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.Acknowledgement)}>
                        <p className='dropdown-selected-bold'>{translation('Acknowledgement')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Acknowledgement) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Acknowledgement) ? 'section-open' : ''}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Acknowledgement) ? "100%" : ""}}
                    >
                        {acknowledgement}
                        {
                            !acknowledgmentFilterArticles.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {acknowledgmentFilterArticles.length > 5 && !showSeeAllAcknowledgement ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.Acknowledgement)}>See All</button> : ""}
                    </div>
                </div>
            </div>

        </form>
    );
};

export default Article;