import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    globalSelector,
    setJournalsCategoriesFilter,
    setJournalsDocTypeFilter,
    setJournalsPublisherFilter,
    setJournalsVolumeFilter,
    setLoader,
    setSearchJournalsData
} from "../../../../redux/slices/global/globalSlice";
import {dispatch, orderBySelected, prepareParams, query, requestKeysUpdate, translation} from "../../../../helpers";
import {
    journalsCategoriesFilterApi,
    journalsDocTypeFilterApi,
    journalsPublisherFilterApi,
    journalsVolumeFilterApi,
    searchDataJournalsApi
} from "../../../../helpers/apis";
import ArrowDownSVG from "../../../../assets/images/arrowDown.svg";

const Journals = ({setCheckedValues}) => {
    const [toggleArrow, setToggleArrow] = useState([]);
    const [showSeeAllPublicationYears, setShowSeeAllPublicationYears] = useState(false);
    const [showSeeAllASCICategories, setShowSeeAllASCICategories] = useState(false);
    const [showSeeAllDocumentTypes, setShowSeeAllDocumentTypes] = useState(false);
    const [showSeeAllPublishers, setShowSeeAllPublishers] = useState(false);
    const {journalsVolumeFilter, journalsPublisherFilter, journalsDocTypeFilter, journalsCategoriesFilter} = useSelector(globalSelector);
    const checkBoxesLocalStorage = JSON.parse(localStorage.getItem("CheckBoxes")) || [];
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));
    const checkboxesArr = JSON.parse(localStorage.getItem("searchDataApiArr"));
    const history = useHistory();

    const FilterItemEnum = {
        PublicationYears: "PublicationYears",
        // DocumentTypes: "DocumentTypes",
        Publishers: "Publishers",
        ASCICategories: "ASCICategories",
    };

    const mergedArr = searchObj.addedRows?.length ?
        [prepareParams(searchObj.inputsData, requestKeysUpdate)].concat(searchObj.addedRows) :
        [prepareParams(searchObj.inputsData, requestKeysUpdate)];

    const searchDataApiAuthorBody = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
        "offset": 0,
        "limit": 10,
    };

    if(localStorage.getItem("sortByOption")){
        searchDataApiAuthorBody["order_by"] = orderBySelected(localStorage.getItem("sortByOption"))
    }

    const searchDataApiArr = {
        ecoom_categories: [],
        document_types: [],
        publishers: [],
        volumes: []
    };

    const FiltersBodyObj = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
    };

    useEffect(() => {
        if (history.action === "POP") {

            if (checkboxesArr?.ecoom_categories.length) {
                FiltersBodyObj["ecoom_categories"] = checkboxesArr.ecoom_categories;
                searchDataApiAuthorBody["ecoom_categories"] = checkboxesArr.ecoom_categories
            }

            if (checkboxesArr?.publishers.length) {
                FiltersBodyObj["publishers"] = checkboxesArr.publishers;
                searchDataApiAuthorBody["publishers"] = checkboxesArr.publishers
            }

            // if (checkboxesArr?.document_types.length) {
            //     FiltersBodyObj["document_types"] = checkboxesArr.document_types;
            //     searchDataApiAuthorBody["document_types"] = checkboxesArr.document_types
            // }

            if (checkboxesArr?.volumes.length) {
                FiltersBodyObj["volumes"] = checkboxesArr.volumes;
                searchDataApiAuthorBody["volumes"] = checkboxesArr.volumes
            }

            searchDataJournalsApi(searchDataApiAuthorBody)
                .then((data) => {
                    journalsCategoriesFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setJournalsCategoriesFilter(data)));

                    journalsPublisherFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setJournalsPublisherFilter(data)));

                    // journalsDocTypeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setJournalsDocTypeFilter(data)));

                    journalsVolumeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setJournalsVolumeFilter(data)));

                    dispatch(setSearchJournalsData(data))
                })
                .finally(() => dispatch(setLoader(false)));

        }
    }, [history]);

    const handleCheckboxChange = (id, type) => {
        const updatedCheckBoxes = [...checkBoxesLocalStorage];
        const index = updatedCheckBoxes.findIndex((checkbox) => checkbox.id === id);

        if (index >= 0) {
            updatedCheckBoxes.splice(index, 1);
        } else {
            updatedCheckBoxes.push({id, type});
        }

        setCheckedValues([]);

        updatedCheckBoxes.forEach((checkbox) => {
            switch (checkbox.type) {
                case "ASCICategories":
                    searchDataApiArr.ecoom_categories.push(Number(checkbox.id));
                    break;
                case "PublicationYears":
                    searchDataApiArr.volumes.push(Number(checkbox.id));
                    break;
                case "Publishers":
                    searchDataApiArr.publishers.push(Number(checkbox.id));
                    break;
                // case "DocumentTypes":
                //     searchDataApiArr.document_types.push(Number(checkbox.id));
                //     break;
                default:
                    break;
            }
        });

        if (searchDataApiArr.ecoom_categories.length) {
            FiltersBodyObj["ecoom_categories"] = searchDataApiArr.ecoom_categories;
            searchDataApiAuthorBody["ecoom_categories"] = searchDataApiArr.ecoom_categories
        }

        if (searchDataApiArr.publishers.length) {
            FiltersBodyObj["publishers"] = searchDataApiArr.publishers;
            searchDataApiAuthorBody["publishers"] = searchDataApiArr.publishers
        }

        // if (searchDataApiArr.document_types.length) {
        //     FiltersBodyObj["document_types"] = searchDataApiArr.document_types;
        //     searchDataApiAuthorBody["document_types"] = searchDataApiArr.document_types
        // }

        if (searchDataApiArr.volumes.length) {
            FiltersBodyObj["volumes"] = searchDataApiArr.volumes;
            searchDataApiAuthorBody["volumes"] = searchDataApiArr.volumes
        }

        localStorage.setItem('CheckBoxes', JSON.stringify(updatedCheckBoxes));
        localStorage.setItem('searchDataApiArr', JSON.stringify(searchDataApiArr));

        dispatch(setLoader(true));
        searchDataJournalsApi(searchDataApiAuthorBody)
            .then((data) => {

                if (type !== "ASCICategories") {
                    journalsCategoriesFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setJournalsCategoriesFilter(data)));
                }

                if (type !== "Publishers") {
                    journalsPublisherFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setJournalsPublisherFilter(data)));
                }

                // if (type !== "DocumentTypes") {
                //     journalsDocTypeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setJournalsDocTypeFilter(data)));
                // }

                if (type !== "PublicationYears") {
                    journalsVolumeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setJournalsVolumeFilter(data)));
                }

                dispatch(setSearchJournalsData(data))
            })
            .finally(() => dispatch(setLoader(false)));
    };

    const seeAll = (e, filter) => {
        e.preventDefault();
        switch (filter) {
            // case "DocumentTypes":
            //     setShowSeeAllDocumentTypes(true);
            //     break;
            case "ASCICategories":
                setShowSeeAllASCICategories(true);
                break;
            case "Publishers":
                setShowSeeAllPublishers(true);
                break;
            case "PublicationYears":
                setShowSeeAllPublicationYears(true);
                break;
            default:
                return null
        }
    };

    const targetSection = (id) => {
        setToggleArrow([...toggleArrow, id]);
        if (toggleArrow.includes(id)) {
            const newArray = toggleArrow.filter(item => item !== id);
            setToggleArrow(newArray);
        }
    };

    const categoriesFilter = journalsCategoriesFilter.length && !showSeeAllASCICategories ? journalsCategoriesFilter.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ASCICategories)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    }) : journalsCategoriesFilter.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.ASCICategories)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    });

    const publicationYears = journalsVolumeFilter.length && !setShowSeeAllPublicationYears ? journalsVolumeFilter.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.year}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.year)}
                           onChange={() => handleCheckboxChange(item.year, FilterItemEnum.PublicationYears)}/>
                    <label htmlFor={item.year} title={item.year}>{item.year}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    }) : journalsVolumeFilter.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.year}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.year)}
                           onChange={() => handleCheckboxChange(item.year, FilterItemEnum.PublicationYears)}/>
                    <label htmlFor={item.year} title={item.year}>{item.year}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    });

    const publishers = journalsPublisherFilter.length && !setShowSeeAllPublishers ? journalsPublisherFilter.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.Publishers)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    }) : journalsPublisherFilter.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.Publishers)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    });

    // const docType = journalsDocTypeFilter.length && !setShowSeeAllDocumentTypes ? journalsDocTypeFilter.slice(0, 5).map((item, i) => {
    //     return (
    //         <div key={i} className="drop-down-section_button">
    //             {
    //                 item.name ? <>
    //                     <p className="custom-checkbox">
    //                         <input type="checkbox" id={item.id}
    //                                checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
    //                                onChange={() => handleCheckboxChange(item.id, FilterItemEnum.DocumentTypes)}/>
    //                         <label htmlFor={item.id} title={item.name}>{item.name}</label>
    //                     </p>
    //                     <p className='public-text'>{item.count}</p>
    //                 </> : ""
    //             }
    //         </div>
    //     )
    // }) : journalsDocTypeFilter.map((item, i) => {
    //     return (
    //         <div key={i} className="drop-down-section_button">
    //             {
    //                 item.name ? <>
    //                     <p className="custom-checkbox">
    //                         <input type="checkbox" id={item.id}
    //                                checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
    //                                onChange={() => handleCheckboxChange(item.id, FilterItemEnum.DocumentTypes)}/>
    //                         <label htmlFor={item.id} title={item.name}>{item.name}</label>
    //                     </p>
    //                     <p className='public-text'>{item.count}</p>
    //                 </> : ""
    //             }
    //         </div>
    //     )
    // });

    return (
        <form className='filter-form home_form'>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.PublicationYears)}>
                        <p className='dropdown-selected-bold'>{translation('Publication Years')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? 'section-open' : !journalsVolumeFilter.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? "100%" : ""}}>
                        {publicationYears}
                        {
                            !journalsVolumeFilter.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {journalsVolumeFilter.length > 5 && !showSeeAllPublicationYears ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.PublicationYears)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.ASCICategories)}>
                        <p className='dropdown-selected-bold'>{translation('ASCICategories')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? 'section-open' : !journalsCategoriesFilter.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.ASCICategories) ? "100%" : ""}}>
                        {categoriesFilter}
                        {
                            !journalsCategoriesFilter.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {journalsCategoriesFilter.length > 5 && !showSeeAllASCICategories ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.ASCICategories)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            {/*<div className='home_form-item filter_form-item'>*/}
            {/*    <div className='custom-select'>*/}
            {/*        <div className="user_container" onClick={() => targetSection(FilterItemEnum.DocumentTypes)}>*/}
            {/*            <p className='dropdown-selected-bold'>{translation('Document Types')}</p>*/}
            {/*            <img src={ArrowDownSVG}*/}
            {/*                 className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocumentTypes) ? 'select-close' : 'select-open'}*/}
            {/*                 alt='arrow-icon'/>*/}
            {/*        </div>*/}
            {/*        <div*/}
            {/*            className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocumentTypes) ? 'section-open' : !journalsDocTypeFilter.length ? 'section-close' : ""}`}*/}
            {/*            style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocumentTypes) ? "100%" : ""}}>*/}
            {/*            {docType}*/}
            {/*            {*/}
            {/*                !journalsDocTypeFilter.length ?*/}
            {/*                    <p className="no-data-filters">{translation("No data")}</p> : ""*/}
            {/*            }*/}
            {/*            {journalsDocTypeFilter.length > 5 && !showSeeAllDocumentTypes ?*/}
            {/*                <button className='link-primary'*/}
            {/*                        onClick={(e) => seeAll(e, FilterItemEnum.DocumentTypes)}>See All</button> : ""}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.Publishers)}>
                        <p className='dropdown-selected-bold'>{translation('Publishers')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Publishers) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Publishers) ? 'section-open' : !journalsPublisherFilter.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.Publishers) ? "100%" : ""}}>
                        {publishers}
                        {
                            !journalsPublisherFilter.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {journalsPublisherFilter.length > 5 && !showSeeAllPublishers ?
                            <button className='link-primary' onClick={(e) => seeAll(e, FilterItemEnum.Publishers)}>See
                                All</button> : ""}
                    </div>
                </div>
            </div>

        </form>
    );
};

export default Journals;