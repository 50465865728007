import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    globalSelector,
    setCitedWorkDocTypeFilter,
    setCitedWorkVolumeFilter,
    setLoader,
    setSearchDataCitedWork
} from "../../../../redux/slices/global/globalSlice";
import {dispatch, orderBySelected, prepareParams, query, requestKeysUpdate, translation} from "../../../../helpers";
import {citedWorkDocTypeFilterApi, citedWorkVolumeFilterApi, searchDataCitedWorkApi} from "../../../../helpers/apis";
import ArrowDownSVG from "../../../../assets/images/arrowDown.svg";

const CitedWork = ({setCheckedValues}) => {
    const [toggleArrow, setToggleArrow] = useState([]);
    const [showSeeAllPublicationYears, setShowSeeAllPublicationYears] = useState(false);
    const [showSeeAllDocType, setShowSeeAllDocType] = useState(false);
    const {citedWorkDocTypeFilter, citedWorkVolumeFilter} = useSelector(globalSelector);
    const checkBoxesLocalStorage = JSON.parse(localStorage.getItem("CheckBoxes")) || [];
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));
    const checkboxesArr = JSON.parse(localStorage.getItem("searchDataApiArr"));
    const history = useHistory();

    const FilterItemEnum = {
        PublicationYears: "PublicationYears",
        DocType: "DocType",
    };

    const mergedArr = searchObj.addedRows?.length ?
        [prepareParams(searchObj.inputsData, requestKeysUpdate)].concat(searchObj.addedRows) :
        [prepareParams(searchObj.inputsData, requestKeysUpdate)];

    const searchDataApiAuthorBody = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
        "offset": 0,
        "limit": 10,
    };

    if(localStorage.getItem("sortByOption")){
        searchDataApiAuthorBody["order_by"] = orderBySelected(localStorage.getItem("sortByOption"))
    }

    const searchDataApiArr = {
        volumes: [],
        docType: []
    };

    const FiltersBodyObj = {
        "version": query().searchType === 'ASCI' ? 1 : 2,
        "query": mergedArr,
    };

    useEffect(() => {
        if (history.action === "POP") {

            if (checkboxesArr?.docType.length) {
                FiltersBodyObj["document_types"] = checkboxesArr.docType;
                searchDataApiAuthorBody["document_types"] = checkboxesArr.docType
            }

            if (checkboxesArr?.volumes.length) {
                FiltersBodyObj["cited_publ_years"] = checkboxesArr.volumes;
                searchDataApiAuthorBody["cited_publ_years"] = checkboxesArr.volumes
            }

            searchDataCitedWorkApi(searchDataApiAuthorBody)
                .then((data) => {

                    citedWorkDocTypeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setCitedWorkDocTypeFilter(data)));

                    citedWorkVolumeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setCitedWorkVolumeFilter(data)));

                    dispatch(setSearchDataCitedWork(data))
                })
                .finally(() => dispatch(setLoader(false)));
        }

    }, [history]);

    const handleCheckboxChange = (id, type) => {
        const updatedCheckBoxes = [...checkBoxesLocalStorage];
        const index = updatedCheckBoxes.findIndex((checkbox) => checkbox.id === id);

        if (index >= 0) {
            updatedCheckBoxes.splice(index, 1);
        } else {
            updatedCheckBoxes.push({id, type});
        }

        setCheckedValues([]);

        updatedCheckBoxes.forEach((checkbox) => {
            switch (checkbox.type) {
                case "DocType":
                    searchDataApiArr.docType.push(Number(checkbox.id));
                    break;
                case "PublicationYears":
                    searchDataApiArr.volumes.push(Number(checkbox.id));
                    break;
                default:
                    break;
            }
        });

        if (searchDataApiArr.docType.length) {
            FiltersBodyObj["document_types"] = searchDataApiArr.docType;
            searchDataApiAuthorBody["document_types"] = searchDataApiArr.docType
        }

        if (searchDataApiArr.volumes.length) {
            FiltersBodyObj["cited_publ_years"] = searchDataApiArr.volumes;
            searchDataApiAuthorBody["cited_publ_years"] = searchDataApiArr.volumes
        }

        localStorage.setItem('CheckBoxes', JSON.stringify(updatedCheckBoxes));
        localStorage.setItem('searchDataApiArr', JSON.stringify(searchDataApiArr));

        dispatch(setLoader(true));
        searchDataCitedWorkApi(searchDataApiAuthorBody)
            .then((data) => {

                if (type !== "DocType") {
                    citedWorkDocTypeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setCitedWorkDocTypeFilter(data)));
                }

                if (type !== "PublicationYears") {
                    citedWorkVolumeFilterApi(FiltersBodyObj, {language: data.language}).then((data) => dispatch(setCitedWorkVolumeFilter(data)));
                }

                dispatch(setSearchDataCitedWork(data))
            })
            .finally(() => dispatch(setLoader(false)));
    };

    const seeAll = (e, filter) => {
        e.preventDefault();
        switch (filter) {
            case "DocType":
                setShowSeeAllDocType(true);
                break;
            case "PublicationYears":
                setShowSeeAllPublicationYears(true);
                break;
            default:
                return null
        }
    };

    const targetSection = (id) => {
        setToggleArrow([...toggleArrow, id]);
        if (toggleArrow.includes(id)) {
            const newArray = toggleArrow.filter(item => item !== id);
            setToggleArrow(newArray);
        }
    };

    const docType = citedWorkDocTypeFilter.length && !showSeeAllDocType ? citedWorkDocTypeFilter.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.DocType)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    }) : citedWorkDocTypeFilter.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                {
                    item.name ? <>
                        <p className="custom-checkbox">
                            <input type="checkbox" id={item.id}
                                   checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.id)}
                                   onChange={() => handleCheckboxChange(item.id, FilterItemEnum.DocType)}/>
                            <label htmlFor={item.id} title={item.name}>{item.name}</label>
                        </p>
                        <p className='public-text'>{item.count}</p>
                    </> : ""
                }
            </div>
        )
    });

    const publicationYears = citedWorkVolumeFilter.length && !setShowSeeAllPublicationYears ? citedWorkVolumeFilter.slice(0, 5).map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.year}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.year)}
                           onChange={() => handleCheckboxChange(item.year, FilterItemEnum.PublicationYears)}/>
                    <label htmlFor={item.year} title={item.year}>{item.year}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    }) : citedWorkVolumeFilter.map((item, i) => {
        return (
            <div key={i} className="drop-down-section_button">
                <p className="custom-checkbox">
                    <input type="checkbox" id={item.year}
                           checked={checkBoxesLocalStorage.some((checkbox) => checkbox.id === item.year)}
                           onChange={() => handleCheckboxChange(item.year, FilterItemEnum.PublicationYears)}/>
                    <label htmlFor={item.year} title={item.year}>{item.year}</label>
                </p>
                <p className='public-text'>{item.count}</p>
            </div>
        )
    });

    return (
        <form className='filter-form home_form'>
            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.PublicationYears)}>
                        <p className='dropdown-selected-bold'>{translation('Publication Years')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? 'section-open' : !citedWorkVolumeFilter.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.PublicationYears) ? "100%" : ""}}>
                        {publicationYears}
                        {
                            !citedWorkVolumeFilter.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {citedWorkVolumeFilter.length > 5 && !showSeeAllPublicationYears ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.PublicationYears)}>See All</button> : ""}
                    </div>
                </div>
            </div>

            <div className='home_form-item filter_form-item'>
                <div className='custom-select'>
                    <div className="user_container" onClick={() => targetSection(FilterItemEnum.DocType)}>
                        <p className='dropdown-selected-bold'>{translation('DocType')}</p>
                        <img src={ArrowDownSVG}
                             className={toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocType) ? 'select-close' : 'select-open'}
                             alt='arrow-icon'/>
                    </div>
                    <div
                        className={`drop-down-section scroller ${toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocType) ? 'section-open' : !citedWorkDocTypeFilter.length ? 'section-close' : ""}`}
                        style={{maxHeight: toggleArrow?.length && toggleArrow.includes(FilterItemEnum.DocType) ? "100%" : ""}}>
                        {docType}
                        {
                            !citedWorkDocTypeFilter.length ?
                                <p className="no-data-filters">{translation("No data")}</p> : ""
                        }
                        {citedWorkDocTypeFilter.length > 5 && !showSeeAllDocType ?
                            <button className='link-primary'
                                    onClick={(e) => seeAll(e, FilterItemEnum.DocType)}>See All</button> : ""}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CitedWork;