import React from 'react';
import {handleChange} from "../../helpers";

const Organizations = ({setInputsData, inputsData, handleChangeRows, value, index}) => {
    return (
        <div className='home_form-item'>
            <input
                type='text'
                className='home_form-item_input'
                placeholder='Organization Name'
                name='organization_name'
                autoComplete="off"
                value={inputsData ? inputsData.organization_name : value.organization_name}
                maxLength={40}
                onChange={inputsData ? (e)=> handleChange(e, setInputsData, inputsData) : (e)=> handleChangeRows(e,index)}
            />
        </div>
    );
};

export default Organizations;