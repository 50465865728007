import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {
    detectSearchTypeUrl,
    dispatch, exportAsCsv,
    orderBySelected,
    query,
    searchByOnlyMainApi,
    translation
} from "../../../helpers";
import ArrowDownSVG from "../../../assets/images/arrowDown.svg";
import SearchResults from "./SearchResults";
import {globalSelector, setLoader} from "../../../redux/slices/global/globalSlice";
import PaginationByPage from "./PaginationByPage";
import {useSelector} from "react-redux";
import useOutsideClickMultipleHandler from "../../../helpers/custom-hooks/outSideClickMultiple";

const Content = ({setCheckedValues, checkedValues}) => {
    const {searchResultsAuthor, searchResultsArticle, searchResultJournals, searchResultOrganizations, searchResultCitedWork} = useSelector(globalSelector);
    const [exportResultsArr] = useState(['Export Results']);
    const [exportResultsOption, setExportResultsOption] = useState(exportResultsArr[0]);
    const [pageSizesArr] = useState(['10', '20', '50']);
    const [pageSizesOption, setPageSizesOption] = useState(pageSizesArr[0]);
    const [sortByArrAuthor] = useState(['First Name A-Z', 'First Name Z-A', 'Last Name A-Z', 'Last Name Z-A']);
    const [sortByArrArticle] = useState(['Year A-Z', 'Year Z-A', 'Citations A-Z', 'Citations Z-A']);
    const [sortByArrJournal] = useState(['Name A-Z', 'Name Z-A']);
    const [sortByArrORG] = useState(['Name A-Z', 'Name Z-A', 'Year A-Z', 'Year Z-A']);
    const [sortByArrCited] = useState(['Cited A-Z', 'Cited Z-A', 'Cited Year A-Z', 'Cited Year Z-A']);
    const [active, setActive] = useState(null);
    const location = useLocation();
    const history = useHistory();

    const handleOutsideClick = () => {
        setActive(null);
    };

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const refs = useOutsideClickMultipleHandler(handleOutsideClick);

    refs.current = [ref1, ref2, ref3];

    const searchArray = () => {
        switch (detectSearchTypeUrl(query())) {
            case "Documents":
                return searchResultsArticle;
            case "Authors":
                return searchResultsAuthor;
            case "Journals":
                return searchResultJournals;
            case "Organizations":
                return searchResultOrganizations;
            case "Cited References":
                return searchResultCitedWork;
            case "ids":
                return searchResultsArticle;
            default:
                return []
        }
    };

    const handleCheckboxChange = (id) => {
        if (id === "selectAll") {
            const allChecked = searchArray()?.results?.length === checkedValues.length;

            if (allChecked) {
                setCheckedValues([]);
            } else {
                const allIds = searchArray()?.results?.map((result) => Number(result.id));
                setCheckedValues(allIds);
            }
        } else {
            let updatedCheckBoxes = new Set(checkedValues);

            if (updatedCheckBoxes.has(Number(id))) {
                updatedCheckBoxes = updatedCheckBoxes.delete(id);
            } else {
                updatedCheckBoxes.add(Number(id));
            }

            setCheckedValues(Array.from(updatedCheckBoxes));
        }
    };

    const updateURLQuery = (offset, limit) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('offset', offset.toString());
        searchParams.set('limit', limit.toString());
        history.push({search: searchParams.toString()});
    };

    const FilterOrderBy = () => {
        switch (detectSearchTypeUrl(query())) {
            case "Documents":
                return sortByArrArticle[0];
            case "Authors":
                return sortByArrAuthor[0];
            case "Journals":
                return sortByArrJournal[0];
            case "Organizations":
                return sortByArrORG[0];
            case "Cited References":
                return sortByArrCited[0];
            case "ids":
                return sortByArrArticle[0];
            default:
                return []
        }
    };

    const mapSortBy = () => {
        switch (detectSearchTypeUrl(query())) {
            case "Documents":
                return sortByArrArticle;
            case "Authors":
                return sortByArrAuthor;
            case "Journals":
                return sortByArrJournal;
            case "Organizations":
                return sortByArrORG;
            case "Cited References":
                return sortByArrCited;
            case "ids":
                return sortByArrArticle;
            default:
                return []
        }
    };

    const [sortByOption, setSortyByOption] = useState(FilterOrderBy());

    useEffect(()=>{
        if(!localStorage.getItem("sortByOption")){
            localStorage.setItem("sortByOption", FilterOrderBy())
        }
    },[]);

    useEffect(() => {
        setSortyByOption(localStorage.getItem("sortByOption") || FilterOrderBy())
    }, [query()]);

    const ContentFilterItemEnum = {
        ExportResults: 0,
        PageSize: 1,
        SortBy: 2
    };

    const targetSection = (id) => {
        if (active === id) {
            setActive(null);
        } else {
            setActive(id);
        }
    };

    const handleUserChange = (section, select) => {
        if (select === ContentFilterItemEnum.PageSize) {
            setPageSizesOption(section);
            dispatch(setLoader(true));
            searchByOnlyMainApi(detectSearchTypeUrl(query()), orderBySelected(sortByOption), Number(section), 0);
            setCheckedValues([]);
            updateURLQuery(0, Number(section))

        } else if (select === ContentFilterItemEnum.ExportResults) {
            setExportResultsOption(section);
        } else if (select === ContentFilterItemEnum.SortBy) {
            localStorage.setItem("sortByOption", section);
            setSortyByOption(section);
            setCheckedValues([]);
            dispatch(setLoader(true));
            searchByOnlyMainApi(detectSearchTypeUrl(query()), orderBySelected(section), 10, 0);
        }
        setActive(null);
    };

    if(!searchArray()?.results?.length){
        return null
    }

    return (
        <section className='content'>
            <div className='content_filter'>
                <div className="form-row tb--align-center">
                    <label className='custom-input-checkbox'>
                        <input
                            type="checkbox"
                            checked={checkedValues.length === searchArray()?.results?.length}
                            onChange={() => handleCheckboxChange("selectAll")}
                        />
                        <span className="checkmark"></span></label>
                    <span className='info_count'>{checkedValues.length}/{searchArray()?.results?.length}</span>
                    {/*export values*/}
                    <div className='col-auto'>
                        <div className='content_filter-item'>
                            <div className='custom-select' ref={ref1}>
                                <div className="user_container"
                                     onClick={() => targetSection(ContentFilterItemEnum.ExportResults)}>
                                    <p className='dropdown-selected-bold'>{exportResultsOption}</p>
                                    <img src={ArrowDownSVG}
                                         className={active === ContentFilterItemEnum.ExportResults ? 'select-close' : 'select-open'}
                                         alt='arrow-icon'/>
                                </div>
                                <div
                                    className={`drop-down-section scroller ${active === ContentFilterItemEnum.ExportResults ? 'section-open' : ''}`}>
                                    <p onClick={() => {
                                        setActive(null);
                                        checkedValues.length ?
                                            exportAsCsv(checkedValues) :
                                            exportAsCsv(query())
                                    }}
                                       className="drop-down-section_button">
                                        {translation(" Export as csv")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-auto ml-auto flex-gap-10 tb--flex'>
                        <div className='content_filter-item'>
                            <div className='custom-select' ref={ref2}>
                                <div className="user_container text-capitalize"
                                     onClick={() => targetSection(ContentFilterItemEnum.PageSize)}>
                                    <p className='dropdown-selected-bold'><span
                                        className='fw-100'>{translation('Page Size: ')}</span>{pageSizesOption}</p>
                                    <img src={ArrowDownSVG}
                                         className={active === ContentFilterItemEnum.PageSize ? 'select-close' : 'select-open'}
                                         alt='arrow-icon'/>
                                </div>
                                <div
                                    className={`drop-down-section scroller ${active === ContentFilterItemEnum.PageSize ? 'section-open' : ''}`}>
                                    {
                                        pageSizesArr.map((item, i) => {
                                            return (
                                                <p
                                                    key={i}
                                                    onClick={() => handleUserChange(item, ContentFilterItemEnum.PageSize)}
                                                    className="drop-down-section_button">
                                                    {item}
                                                </p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='content_filter-item'>
                            <div className='custom-select' ref={ref3}>
                                <div className="user_container text-capitalize"
                                     onClick={() => targetSection(ContentFilterItemEnum.SortBy)}>
                                    <p className='dropdown-selected-bold'><span
                                        className='fw-100'>{translation('Sort By: ')} </span> {sortByOption}</p>
                                    <img src={ArrowDownSVG}
                                         className={active === ContentFilterItemEnum.SortBy ? 'select-close' : 'select-open'}
                                         alt='arrow-icon'/>
                                </div>
                                <div
                                    className={`drop-down-section scroller ${active === ContentFilterItemEnum.SortBy ? 'section-open' : ''}`}>
                                    {
                                        mapSortBy().map((item, i) => {
                                            return (
                                                <p
                                                    key={i}
                                                    onClick={() => handleUserChange(item, ContentFilterItemEnum.SortBy)}
                                                    className="drop-down-section_button">
                                                    {item}
                                                </p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='info_list'>
                <SearchResults handleCheckboxChange={handleCheckboxChange} checkedValues={checkedValues}/>
            </div>
            <PaginationByPage pageSizesOption={pageSizesOption} updateURLQuery={updateURLQuery} searchArray={searchArray()} setCheckedValues={setCheckedValues}/>
        </section>
    );
};

export default Content;