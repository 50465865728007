import {AxiosInstance} from "./AxiosInstance";
import {requestsLanguage} from "../index";

export const API_URL = 'https://api.asci.am/api';

const SEARCH_URL_AUTHOR = `${API_URL}/scientists`;
const SEARCH_ORG_FILTER_AUTHOR = `${SEARCH_URL_AUTHOR}/organization-filter`;
const NAMES_BY_ID_AUTHOR = `${SEARCH_URL_AUTHOR}/names-by-ids`;
const REFERENCE_COUNT_BY_ID_AUTHOR = `${SEARCH_URL_AUTHOR}/article-reference-count-by-ids`;
const ARTICLES_BY_ID_AUTHOR = `${SEARCH_URL_AUTHOR}/articles-by-ids`;
const CATEGORIES_FILTER_AUTHOR = `${SEARCH_URL_AUTHOR}/ecoom-category-filter`;
const NAMES_FILTER_AUTHOR = `${SEARCH_URL_AUTHOR}/name-filter`;
const AUTHORS_DONLOAD_CSV = `${SEARCH_URL_AUTHOR}/download-csv`;
const AUTHORS_DONLOAD_CSV_BY_IDS = `${SEARCH_URL_AUTHOR}/download-csv-by-ids`;

const SEARCH_URL_ARTICLES = `${API_URL}/articles`;
const VOLUME_FILTER_URL_ARTICLES = `${SEARCH_URL_ARTICLES}/volume-filter`;
const DOCUMENT_TYPE_FILTER_URL_ARTICLES = `${SEARCH_URL_ARTICLES}/document-type-filter`;
const ARM_JOURNAL_FILTER_URL_ARTICLES = `${SEARCH_URL_ARTICLES}/arm-journal-filter`;
const CATEGORIES_FILTER_URL_ARTICLES = `${SEARCH_URL_ARTICLES}/ecoom-category-filter`;
const AUTHOR_FILTER_URL_ARTICLES = `${SEARCH_URL_ARTICLES}/author-filter`;
const ORG_FILTER_URL_ARTICLES = `${SEARCH_URL_ARTICLES}/organization-filter`;
const LANGUAGE_FILTER_URL_ARTICLES = `${SEARCH_URL_ARTICLES}/language-filter`;
const ACKNOWLEDGMENT_FILTER_URL_ARTICLES = `${SEARCH_URL_ARTICLES}/acknowledgement-filter`;
const FUNDING_FILTER_URL_ARTICLES = `${SEARCH_URL_ARTICLES}/funding-filter`;
const ARTICLES_DOWNLOAD_CSV = `${SEARCH_URL_ARTICLES}/download-csv`;
const ARTICLES_DOWNLOAD_CSV_BY_ID = `${SEARCH_URL_ARTICLES}/download-csv/`;

const SEARCH_URL_JOURNAL = `${API_URL}/arm-journals`;
const JOURNAL_VOLUME_FILTER = `${SEARCH_URL_JOURNAL}/volume-Filter`;
const JOURNAL_CATEGORIES_FILTER = `${SEARCH_URL_JOURNAL}/ecoom-category-Filter`;
const JOURNAL_DOC_TYPE_FILTER = `${SEARCH_URL_JOURNAL}/document-type-Filter`;
const JOURNAL_PUBLISHER_FILTER = `${SEARCH_URL_JOURNAL}/publisher-Filter`;
const JOURNAL_NAME_BY_ID = `${SEARCH_URL_JOURNAL}/name-by-id`;
const JOURNAL_ARTICLE_COUNT_BY_ID = `${SEARCH_URL_JOURNAL}/article-reference-count-by-id`;
const JOURNAL_ARTICLES_BY_ID = `${SEARCH_URL_JOURNAL}/articles-by-id`;
const JOURNAL_HOME_CATEGORIES = `${API_URL}/ecoom-categories`;
const JOURNAL_DOWNLOAD_CSV = `${SEARCH_URL_JOURNAL}/download-csv`;
const JOURNAL_DOWNLOAD_CSV_BY_ID = `${SEARCH_URL_JOURNAL}/download-csv-by-id`;

const SEARCH_URL_ORG = `${API_URL}/organizations`;
const ORG_CATEGORIES_FILTER = `${SEARCH_URL_ORG}/ecoom-category-Filter`;
const ORG_VOLUME_FILTER = `${SEARCH_URL_ORG}/volume-Filter`;
const ORG_DOWNLOAD_CSV = `${SEARCH_URL_ORG}/download-csv`;
const ORG_DOWNLOAD_CSV_BY_ID = `${SEARCH_URL_ORG}/download-csv/`;
const ORG_NAME_ID = `${SEARCH_URL_ORG}/name`;
const ORG_ARTICLE_REFERENCE_COUNT = `${SEARCH_URL_ORG}/article-reference-count`;
const ORG_ARTICLES = `${SEARCH_URL_ORG}/articles`;

const SEARCH_URL_CITED_WORK = `${API_URL}/references`;
const CITED_WORK_DOWNLOAD_CSV = `${SEARCH_URL_CITED_WORK}/download-csv`;
const CITED_WORK_VOLUME_FILTER = `${SEARCH_URL_CITED_WORK}/cited-publ-year-filter`;
const CITED_WORK_DOC_TYPE_FILTER = `${SEARCH_URL_CITED_WORK}/document-type-filter`;

export const homePageCounters = () => {
    return AxiosInstance('GET', API_URL)
};

// AUTHORS

export const searchDataAuthorApi = (body, headers = {language: requestsLanguage}) => {
    return AxiosInstance('POST', SEARCH_URL_AUTHOR, {body, headers})
};

export const searchORGFiltersAuthorApi = (body, headers) => {
    return AxiosInstance('POST', SEARCH_ORG_FILTER_AUTHOR, {body, headers})
};

export const searchNamesFiltersAuthorApi = (body, headers) => {
    return AxiosInstance('POST', NAMES_FILTER_AUTHOR, {body, headers})
};

export const searchCategoriesFiltersAuthorApi = (body, headers) => {
    return AxiosInstance('POST', CATEGORIES_FILTER_AUTHOR, {body, headers})
};

export const singlePageDataAuthor = (body, headers) => {
    return AxiosInstance('POST', NAMES_BY_ID_AUTHOR, {body, headers})
};

export const singlePageCountersAuthor = (body, headers) => {
    return AxiosInstance('POST', REFERENCE_COUNT_BY_ID_AUTHOR, {body, headers})
};

export const singlePageAuthorArticles = (body, headers) => {
    return AxiosInstance('POST', ARTICLES_BY_ID_AUTHOR, {body, headers})
};

export const authorsCsvApi = (body, headers) => {
    return AxiosInstance('POST', AUTHORS_DONLOAD_CSV, {
        body,
        headers,
        responseType: "blob",
    })
};

export const authorsBYIDCsvApi = (body, headers) => {
    return AxiosInstance('POST', AUTHORS_DONLOAD_CSV_BY_IDS, {
        body,
        headers,
        responseType: "blob",
    })
};

// ARTICLES

export const searchDataArticlesApi = (body, headers = {language: requestsLanguage}) => {
    return AxiosInstance('POST', SEARCH_URL_ARTICLES, {body, headers})
};

export const volumeFilterArticlesApi = (body, headers) => {
    return AxiosInstance('POST', VOLUME_FILTER_URL_ARTICLES, {body, headers})
};

export const docTypeFilterArticlesApi = (body, headers) => {
    return AxiosInstance('POST', DOCUMENT_TYPE_FILTER_URL_ARTICLES, {body, headers})
};

export const armJournalArticlesApi = (body, headers) => {
    return AxiosInstance('POST', ARM_JOURNAL_FILTER_URL_ARTICLES, {body, headers})
};

export const categoriesFilterArticlesApi = (body, headers) => {
    return AxiosInstance('POST', CATEGORIES_FILTER_URL_ARTICLES, {body, headers})
};

export const authorFilterArticlesApi = (body, headers) => {
    return AxiosInstance('POST', AUTHOR_FILTER_URL_ARTICLES, {body, headers})
};

export const orgFilterArticlesApi = (body, headers) => {
    return AxiosInstance('POST', ORG_FILTER_URL_ARTICLES, {body, headers})
};

export const languageFilterArticlesApi = (body, headers) => {
    return AxiosInstance('POST', LANGUAGE_FILTER_URL_ARTICLES, {body, headers})
};

export const acknowledgmentFilterArticlesApi = (body, headers) => {
    return AxiosInstance('POST', ACKNOWLEDGMENT_FILTER_URL_ARTICLES, {body, headers})
};

export const fundingFilterArticlesApi = (body, headers) => {
    return AxiosInstance('POST', FUNDING_FILTER_URL_ARTICLES, {body, headers})
};

export const singlePageArticles = (body, headers) => {
    return AxiosInstance('POST', `${SEARCH_URL_ARTICLES}/${body.id}`, {body, headers})
};

export const articlesCsvApi = (body, headers) => {
    return AxiosInstance("POST", ARTICLES_DOWNLOAD_CSV, {
        body,
        headers,
        responseType: "blob",
    })
};

export const articlesCsvBYIDApi = (body, headers) => {
    return AxiosInstance("POST", `${ARTICLES_DOWNLOAD_CSV_BY_ID}${body.ids[0]}`, {
        body,
        headers,
        responseType: "blob",
    })
};

// JOURNALS

export const searchDataJournalsApi = (body, headers = {language: requestsLanguage}) => {
    return AxiosInstance('POST', SEARCH_URL_JOURNAL, {body, headers})
};

export const journalsVolumeFilterApi = (body, headers) => {
    return AxiosInstance('POST', JOURNAL_VOLUME_FILTER, {body, headers})
};

export const journalsCategoriesFilterApi = (body, headers) => {
    return AxiosInstance('POST', JOURNAL_CATEGORIES_FILTER, {body, headers})
};

// export const journalsDocTypeFilterApi = (body, headers) => {
//     return AxiosInstance('POST', JOURNAL_DOC_TYPE_FILTER, {body, headers})
// };

export const journalsPublisherFilterApi = (body, headers) => {
    return AxiosInstance('POST', JOURNAL_PUBLISHER_FILTER, {body, headers})
};

export const journalSinglePageNameByIdApi = (body, headers) => {
    return AxiosInstance('POST', JOURNAL_NAME_BY_ID, {body, headers})
};

export const journalSinglePageCountByIdApi = (body, headers) => {
    return AxiosInstance('POST', JOURNAL_ARTICLE_COUNT_BY_ID, {body, headers})
};

export const journalSinglePageArticlesByIdApi = (body, headers) => {
    return AxiosInstance('POST', JOURNAL_ARTICLES_BY_ID, {body, headers})
};

export const journalHomeCategoriesApi = (body, headers = {language: localStorage.getItem("lang")}) => {
    return AxiosInstance('POST', JOURNAL_HOME_CATEGORIES, {body, headers})
};

export const journalCsvApi = (body, headers) => {
    return AxiosInstance('POST', JOURNAL_DOWNLOAD_CSV, {
        body,
        headers,
        responseType: "blob",
    })
};

export const journalCsvByIdApi = (body, headers) => {
    return AxiosInstance('POST', JOURNAL_DOWNLOAD_CSV_BY_ID, {
        body,
        headers,
        responseType: "blob",
    })
};

// ORGANIZATION

export const searchDataOrganizationsApi = (body, headers = {language: requestsLanguage}) => {
    return AxiosInstance('POST', SEARCH_URL_ORG, {body, headers})
};

export const organizationsCategoriesFilterApi = (body, headers) => {
    return AxiosInstance('POST', ORG_CATEGORIES_FILTER, {body, headers})
};

export const organizationsVolumeFilterApi = (body, headers) => {
    return AxiosInstance('POST', ORG_VOLUME_FILTER, {body, headers})
};

export const orgSinglePageNameApi = (body, headers) => {
    return AxiosInstance('POST', `${ORG_NAME_ID}/${body.id}`, {body, headers})
};

export const orgSinglePageCountIdApi = (body, headers) => {
    return AxiosInstance('POST', `${ORG_ARTICLE_REFERENCE_COUNT}/${body.id}`, {body, headers})
};

export const orgSinglePageArticlesApi = (body, headers) => {
    return AxiosInstance('POST', `${ORG_ARTICLES}`, {body, headers})
};

export const orgCsvApi = (body, headers) => {
    return AxiosInstance('POST', ORG_DOWNLOAD_CSV, {
        body,
        headers,
        responseType: "blob",
    })
};

export const orgCsvByIdApi = (body, headers) => {
    return AxiosInstance('POST', `${ORG_DOWNLOAD_CSV_BY_ID}${body.id}`, {
        body,
        headers,
        responseType: "blob",
    })
};

// Cited Work

export const searchDataCitedWorkApi = (body, headers = {language: requestsLanguage}) => {
    return AxiosInstance('POST', SEARCH_URL_CITED_WORK, {body, headers})
};

export const citedWorkVolumeFilterApi = (body, headers) => {
    return AxiosInstance('POST', CITED_WORK_VOLUME_FILTER, {body, headers})
};

export const citedWorkDocTypeFilterApi = (body, headers) => {
    return AxiosInstance('POST', CITED_WORK_DOC_TYPE_FILTER, {body, headers})
};

export const citedWorkCsvApi = (body, headers) => {
    return AxiosInstance('POST', CITED_WORK_DOWNLOAD_CSV, {
        body,
        headers,
        responseType: "blob",
    })
};
