import React from 'react';
import Documents from "./Documents";
import Authors from "./Authors";
import Organizations from "./Organizations";
import Journals from "./Journals";
import CitedReferences from "./CitedReferences";

const AddRowsCollection = ({collection, handleChangeRows, index, item, option, setOption, options, setVal, val}) => {
    switch (collection) {
        case 'Documents':
            return <Documents handleChangeRows={handleChangeRows} value={item} index={index} setVal={setVal} val={val} option={option} options={options} setOption={setOption}/>;
        case 'Authors':
            return <Authors handleChangeRows={handleChangeRows} value={item} index={index} setVal={setVal} val={val}/>;
        case 'Organizations':
            return <Organizations handleChangeRows={handleChangeRows} value={item.organization_name || ""} setVal={setVal} val={val} index={index}/>;
        case 'Journals':
            return <Journals handleChangeRows={handleChangeRows} value={item} index={index} setVal={setVal} val={val}/>;
        case 'Cited References':
            return <CitedReferences handleChangeRows={handleChangeRows} value={item.citedWork || ""} setVal={setVal} val={val} index={index}/>;
        case 'ids':
            return <Documents handleChangeRows={handleChangeRows} value={item} index={index} setVal={setVal} val={val} option={option} options={options} setOption={setOption}/>;
        default:
            return null;
    }
};

export default AddRowsCollection;