import React from 'react';
import {useSelector} from "react-redux";
import {globalSelector} from "../../../../redux/slices/global/globalSlice";

const ContentCompany = () => {
    const {orgNameById} = useSelector(globalSelector);

    return (
        <article className='info_item h-100'>
            <h3 className='info_title '>{orgNameById.name}</h3>
            <span className='line'></span>
            <p className='info_caption'>Organization’s names</p>
            <ul className='info_list'>
                <li className='info_list--item'><span className='info_label'>Organization ID:</span>&nbsp;<span
                    className='info_value'>{orgNameById.org_id}</span></li>
                <li className='info_list--item'><span className='info_label'>Organization Web Page:</span>&nbsp;<a
                    href={orgNameById.webpage}
                    className='info_value'>{orgNameById.webpage}</a></li>
                <li className='info_list--item'><span className='info_label'>Organization Email:</span>&nbsp;<span
                    className='info_value'>{orgNameById.email}</span></li>
                <li className='info_list--item'><span className='info_label'>Organization Address:</span>&nbsp;<span
                    className='info_value'>{orgNameById.address_arm}</span></li>
            </ul>
        </article>
    );
};

export default ContentCompany;