import React from 'react';
import {useSelector} from "react-redux";
import {globalSelector} from "../../../../redux/slices/global/globalSlice";

const DocumentsCitations = () => {
    const {singlePageArticles} = useSelector(globalSelector);

    return (
        <aside className="card h-100">
            <div className="card_body">
                <div className='card_item'>
                    <div>
                        <div className='tb--tar'>
                            <p className='number'>{singlePageArticles[0]?.citations_count}</p>
                            <p className='name'>Citations</p>
                        </div>
                    </div>
                </div>
                <div className='card_item'>
                    <div>
                        <div className='tb--tar'>
                            <p className='number'>{singlePageArticles[0]?.references_count}</p>
                            <p className='name'>References</p>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default DocumentsCitations;