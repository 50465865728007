import React from 'react';
import {useSelector} from "react-redux";
import {ReactComponent as ArrowSVG} from "../../../assets/images/icons/arrow.svg";
import {globalSelector, setLimit, setOffset} from "../../../redux/slices/global/globalSlice";
import {
    detectSearchTypeUrl,
    dispatch,
    orderBySelected,
    query,
    searchByOnlyMainApi
} from "../../../helpers";

const PaginationByPage = ({pageSizesOption, updateURLQuery, searchArray, setCheckedValues}) => {
    const {loading} = useSelector(globalSelector);
    const total = (searchArray?.total) / Number(pageSizesOption) || "";
    const offset = Number(query().offset);

    const handlePageChangeNext = () => {
        updateURLQuery(offset + 1, pageSizesOption);
        dispatch(setOffset(offset + 1));
        dispatch(setLimit(Number(pageSizesOption)));
        setCheckedValues([]);
        searchByOnlyMainApi(detectSearchTypeUrl(query()), orderBySelected(localStorage.getItem("sortByOption")), Number(pageSizesOption), offset + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handlePageChangePrev = () => {
        updateURLQuery(offset - 1, pageSizesOption);
        dispatch(setOffset(offset - 1));
        dispatch(setLimit(Number(pageSizesOption)));
        setCheckedValues([]);
        searchByOnlyMainApi(detectSearchTypeUrl(query()), orderBySelected(localStorage.getItem("sortByOption")), Number(pageSizesOption), offset - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    if (loading) {
        return null
    }

    return (
        <nav className='content_pagination custom_pagination'>
            <ul className="pagination">
                {offset > 0 && (
                    <li className="page-item prev" onClick={handlePageChangePrev}>
                        <div className="page-link">
                            <ArrowSVG/>
                        </div>
                    </li>
                )}
                <li className="page-item active"><p className="page-link">{offset + 1}</p></li>
                <li className="page-item"><span>of</span></li>
                <li className="page-item"><p className="page-link">{Math.ceil(total)}</p></li>

                {offset + 1 < Math.ceil(total) && (
                    <li className="page-item next" onClick={handlePageChangeNext}>
                        <div className="page-link">
                            <ArrowSVG/>
                        </div>
                    </li>
                )}
            </ul>
        </nav>

    );
};

export default PaginationByPage;