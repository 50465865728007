import React from 'react';
import {Link} from "react-router-dom";

const ArticlesSimilar = ({singlePageAuthorSimilar}) => {
    return singlePageAuthorSimilar.length ? singlePageAuthorSimilar.map((item, index) => {
        return (
            <article className='info_item' key={index}>
                <span className='info_number'>{index + 1}</span>
                <h3 className='info_title'>
                    <Link to={`/article/${item?.id}`}>{item?.article_name} </Link>
                    {/*<mark>Yerevan</mark>*/}
                </h3>
                <span className="line"></span>
                <div className='info_block'>
                    <p className='info_author'>{item?.author_names.join(', ')}</p>
                    <p className='info_date'>{item?.year} | {item?.arm_journal_name}</p>
                    <p className='info_text'>{item?.abstract}</p>
                </div>
                <span className="line"></span>
                <div className="form-row">
                    <div className="col-auto">
                        <button className="btn-info" type="button" style={{cursor: "default"}}><span>{item?.citations_count}</span>Citations
                        </button>
                    </div>
                    <div className="col-auto">
                        <button className="btn-info" type="button" style={{cursor: "default"}}><span>{item?.references_count}</span>References
                        </button>
                    </div>
                </div>
            </article>
        )
    }) : ""

};

export default ArticlesSimilar;