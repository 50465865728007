import React from 'react';
import {useSelector} from "react-redux";
import Articles from "./Articles/Articles";
import Companies from "./Organization/Companies";
import Authors from "./Authors/Authors";
import Journals from "./Journals/Journals";
import CitedWork from "./CitedWork/CitedWork";
import {globalSelector} from "../../../redux/slices/global/globalSlice";
import {detectSearchTypeUrl, query} from "../../../helpers";

const SearchResults = ({handleCheckboxChange, checkedValues}) => {
    const {searchResultsAuthor, searchResultsArticle, searchResultJournals, searchResultOrganizations, searchResultCitedWork} = useSelector(globalSelector);

    switch (detectSearchTypeUrl(query())) {
        case "Documents":
            return <Articles articleResults={searchResultsArticle?.results} handleCheckboxChange={handleCheckboxChange} checkedValues={checkedValues}/>;
        case "Organizations":
            return <Companies searchResultOrganizations={searchResultOrganizations?.results} handleCheckboxChange={handleCheckboxChange} checkedValues={checkedValues}/>;
        case "Authors":
            return <Authors searchResultsAuthor={searchResultsAuthor?.results} handleCheckboxChange={handleCheckboxChange} checkedValues={checkedValues}/>;
        case "Journals":
            return <Journals searchResultJournals={searchResultJournals?.results} handleCheckboxChange={handleCheckboxChange} checkedValues={checkedValues}/>;
        case "Cited References":
            return <CitedWork searchResultCitedWork={searchResultCitedWork?.results} handleCheckboxChange={handleCheckboxChange} checkedValues={checkedValues}/>;
        case "ids":
            return <Articles articleResults={searchResultsArticle?.results} handleCheckboxChange={handleCheckboxChange} checkedValues={checkedValues}/>;
        default:
            return null
    }
};

export default SearchResults;