import React, {useEffect} from 'react';
import './about.scss'
import {translation, dispatch} from "../../helpers";
import {setLoader} from "../../redux/slices/global/globalSlice";

const About = () => {

    useEffect(() => {
        dispatch(setLoader(false))
    }, []);

    return (
        <main className='about container'>
            <div className='about_container'>
                <h1 className='about_title'>{translation('About')}</h1>
                <p className='about_text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam scelerisque
                    mattis varius. Nulla viverra euismod feugiat. Maecenas scelerisque tellus id ligula pharetra, sed
                    aliquam lacus hendrerit. Fusce congue sem risus, sit amet ultricies ante posuere sed. Proin eget
                    ultricies felis. Phasellus at volutpat leo, nec ornare augue. Suspendisse a tempus mauris.
                    Suspendisse venenatis ac lacus a pellentesque. Fusce eget mauris ut ex porttitor tempus.</p>
                <p className='about_text'>Nulla sed maximus lacus. Aenean ultricies hendrerit libero. Sed efficitur
                    efficitur lorem, vel eleifend lorem sagittis eu. Vestibulum ante ipsum primis in faucibus orci
                    luctus et ultrices posuere cubilia curae; Suspendisse ac vulputate nulla, et condimentum urna. Etiam
                    sollicitudin odio enim, nec viverra elit luctus ac. Donec tincidunt tortor massa, quis tempor orci
                    placerat porttitor. Phasellus lobortis purus vitae mi efficitur, et euismod enim volutpat. In ut
                    pretium ex. In et eros leo. Morbi finibus, urna sed egestas pretium, lacus lectus lobortis eros, vel
                    rhoncus arcu nunc at magna. Ut auctor porta finibus. Nullam fermentum, nisi vel suscipit mattis,
                    ante enim commodo est, vel pretium sapien lorem fringilla dolor. In interdum, ipsum eu pulvinar
                    convallis, enim diam eleifend nunc, nec eleifend ex velit quis magna.</p>
                <p className='about_text'>Aliquam tempus nulla neque, a hendrerit arcu finibus in. Aliquam ipsum libero,
                    dapibus eget rhoncus id, consequat nec orci. Quisque hendrerit tempus justo, eget accumsan lorem
                    sollicitudin vel. Pellentesque volutpat tristique odio ac sagittis. Mauris dictum hendrerit leo,
                    vitae elementum mi efficitur quis. Sed ut orci ac erat rutrum tincidunt varius ut nulla. Integer vel
                    pulvinar nibh. Suspendisse ornare accumsan lectus, ac pretium turpis porta ut. Vestibulum cursus
                    luctus urna non consequat. Sed ornare gravida mi ut fermentum. Quisque eleifend tortor in metus
                    venenatis efficitur. Morbi laoreet velit ut dolor consequat, et eleifend neque rutrum. Donec auctor
                    gravida porta.</p>
                <p className='about_text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam scelerisque
                    mattis varius. Nulla viverra euismod feugiat. Maecenas scelerisque tellus id ligula pharetra, sed
                    aliquam lacus hendrerit. Fusce congue sem risus, sit amet ultricies ante posuere sed. Proin eget
                    ultricies felis. Phasellus at volutpat leo, nec ornare augue. Suspendisse a tempus mauris.
                    Suspendisse venenatis ac lacus a pellentesque. Fusce eget mauris ut ex porttitor tempus.</p>
                <p className='about_text'>Nulla sed maximus lacus. Aenean ultricies hendrerit libero. Sed efficitur
                    efficitur lorem, vel eleifend lorem sagittis eu. Vestibulum ante ipsum primis in faucibus orci
                    luctus et ultrices posuere cubilia curae; Suspendisse ac vulputate nulla, et condimentum urna. Etiam
                    sollicitudin odio enim, nec viverra elit luctus ac. Donec tincidunt tortor massa, quis tempor orci
                    placerat porttitor. Phasellus lobortis purus vitae mi efficitur, et euismod enim volutpat. In ut
                    pretium ex. In et eros leo. Morbi finibus, urna sed egestas pretium, lacus lectus lobortis eros, vel
                    rhoncus arcu nunc at magna. Ut auctor porta finibus. Nullam fermentum, nisi vel suscipit mattis,
                    ante enim commodo est, vel pretium sapien lorem fringilla dolor. In interdum, ipsum eu pulvinar
                    convallis, enim diam eleifend nunc, nec eleifend ex velit quis magna.</p>
                <p className='about_text'>Aliquam tempus nulla neque, a hendrerit arcu finibus in. Aliquam ipsum libero,
                    dapibus eget rhoncus id, consequat nec orci. Quisque hendrerit tempus justo, eget accumsan lorem
                    sollicitudin vel. Pellentesque volutpat tristique odio ac sagittis. Mauris dictum hendrerit leo,
                    vitae elementum mi efficitur quis. Sed ut orci ac erat rutrum tincidunt varius ut nulla. Integer vel
                    pulvinar nibh. Suspendisse ornare accumsan lectus, ac pretium turpis porta ut. Vestibulum cursus
                    luctus urna non consequat. Sed ornare gravida mi ut fermentum. Quisque eleifend tortor in metus
                    venenatis efficitur. Morbi laoreet velit ut dolor consequat, et eleifend neque rutrum. Donec auctor
                    gravida porta.</p>
            </div>
        </main>
    );
};

export default About;