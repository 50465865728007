import {createSlice} from '@reduxjs/toolkit/dist/index';
import i18n from "../../../i18next/i18next";

const initialState = {
    //home
    loading: true,
    locale: i18n.language,
    searchObj: {},
    error: {},
    searchType: "Documents",
    homeCounters: {},
    // authors
    searchResultsAuthor: [],
    orgFiltersAuthor: [],
    namesFilterAuthor: [],
    categoriesFilterAuthor: [],
    singlePageAuthor: {},
    singlePageCountersAuthors: {},
    singlePageAuthorSimilar: {},
    // articles
    searchResultsArticle: [],
    volumeFilterArticles: [],
    doctypeFilterArticles: [],
    armJournalFilterArticles: [],
    categoriesFilterArticles: [],
    authorFilterArticles: [],
    orgFilterArticles: [],
    languageFilterArticles: [],
    acknowledgmentFilterArticles: [],
    fundingFilterArticles: [],
    singlePageArticles: [],
    // journals
    journalsVolumeFilter: [],
    journalsPublisherFilter: [],
    journalsDocTypeFilter: [],
    journalsCategoriesFilter: [],
    searchResultJournals: [],
    journalNameById: [],
    journalCountById: [],
    journalArticleById: [],
    // organizations
    searchResultOrganizations: [],
    orgVolumeFilter: [],
    orgCategoriesFilter: [],
    orgNameById: [],
    orgCountById: [],
    orgArticlesById: [],
    //citedWork
    searchResultCitedWork: [],
    citedWorkDocTypeFilter: [],
    citedWorkVolumeFilter: [],
    // pagination
    offset: 0,
    limit: 10,
    singlePageOffset: 1
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        resetStore: (state) => ({
            ...initialState,
            homeCounters: state.homeCounters,
            loading: state.loading
        }),
        //home
        setSearchObj: (state, action) => {
            state.searchObj = action.payload
        },
        setLoader: (state, action) => {
            state.loading = action.payload
        },
        setLocale: (state, action) => {
            state.locale = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        searchTypeAction: (state, action) => {
            state.searchType = action.payload
        },
        setHomeCounters: (state, action) => {
            state.homeCounters = action.payload
        },

        // Author
        setSearchDataAuthor: (state, action) => {
            state.searchResultsAuthor = action.payload
        },
        setORGFiltersAuthor: (state, action) => {
            state.orgFiltersAuthor = action.payload
        },
        setNamesFiltersAuthor: (state, action) => {
            state.namesFilterAuthor = action.payload
        },
        setCategoriesFilterAuthor: (state, action) => {
            state.categoriesFilterAuthor = action.payload
        },
        setSinglePageDataAuthor: (state, action) => {
            state.singlePageAuthor = action.payload
        },
        setSinglePageCountersAuthor: (state, action) => {
            state.singlePageCountersAuthors = action.payload
        },
        setSinglePageAuthorArticles: (state, action) => {
            state.singlePageAuthorSimilar = action.payload
        },

        // Articles
        setSearchDataArticles: (state, action) => {
            state.searchResultsArticle = action.payload
        },
        setVolumeFilterArticles: (state, action) => {
            state.volumeFilterArticles = action.payload
        },
        setDocTypeFilterArticles: (state, action) => {
            state.doctypeFilterArticles = action.payload
        },
        setArmJournalFilterArticles: (state, action) => {
            state.armJournalFilterArticles = action.payload
        },
        setCategoriesFilterArticles: (state, action) => {
            state.categoriesFilterArticles = action.payload
        },
        setAuthorFilterArticles: (state, action) => {
            state.authorFilterArticles = action.payload
        },
        setORGFiltersArticles: (state, action) => {
            state.orgFilterArticles = action.payload
        },
        setLanguageFilterArticles: (state, action) => {
            state.languageFilterArticles = action.payload
        },
        setAcknowledgmentFilterArticles: (state, action) => {
            state.acknowledgmentFilterArticles = action.payload
        },
        setFundingFilterArticles: (state, action) => {
            state.fundingFilterArticles = action.payload
        },
        setSinglePageArticles: (state, action) => {
            state.singlePageArticles = action.payload
        },

        // Journals
        setJournalsVolumeFilter: (state, action) => {
            state.journalsVolumeFilter = action.payload
        },
        setJournalsPublisherFilter: (state, action) => {
            state.journalsPublisherFilter = action.payload
        },
        setJournalsDocTypeFilter: (state, action) => {
            state.journalsDocTypeFilter = action.payload
        },
        setJournalsCategoriesFilter: (state, action) => {
            state.journalsCategoriesFilter = action.payload
        },
        setSearchJournalsData: (state, action) => {
            state.searchResultJournals = action.payload
        },
        setSingleJournalNameById: (state, action) => {
            state.journalNameById = action.payload
        },
        setSingleJournalCountById: (state, action) => {
            state.journalCountById = action.payload
        },
        setSingleJournalArticlesById: (state, action) => {
            state.journalArticleById = action.payload
        },

        // Organization
        setSearchORGData: (state, action) => {
            state.searchResultOrganizations = action.payload
        },
        setOrgVolumeFilter: (state, action) => {
            state.orgVolumeFilter = action.payload
        },
        setOrgCategoriesFilter: (state, action) => {
            state.orgCategoriesFilter = action.payload
        },
        setOrgSinglePageName: (state, action) => {
            state.orgNameById = action.payload
        },
        setOrgSinglePageCount: (state, action) => {
            state.orgCountById = action.payload
        },
        setOrgSingleArticlesById: (state, action) => {
            state.orgArticlesById = action.payload
        },

        //CitedWork
        setSearchDataCitedWork:(state, action) => {
            state.searchResultCitedWork = action.payload
        },
        setCitedWorkDocTypeFilter:(state, action) => {
            state.citedWorkDocTypeFilter = action.payload
        },
        setCitedWorkVolumeFilter:(state, action) => {
            state.citedWorkVolumeFilter = action.payload
        },

        // PaginationByPage
        setOffset: (state, action) => {
            state.offset = action.payload
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        setSinglePageOffset: (state, action) => {
            state.singlePageOffset = action.payload
        },
    },
});

export const {
    resetStore,
    setLoader,
    setLocale,
    searchTypeAction,
    setHomeCounters,
    setSearchDataAuthor,
    setError,
    setORGFiltersAuthor,
    setCategoriesFilterAuthor,
    setNamesFiltersAuthor,
    setSearchObj,
    setSinglePageDataAuthor,
    setSinglePageCountersAuthor,
    setSinglePageAuthorArticles,
    setSearchDataArticles,
    setVolumeFilterArticles,
    setDocTypeFilterArticles,
    setArmJournalFilterArticles,
    setCategoriesFilterArticles,
    setAuthorFilterArticles,
    setORGFiltersArticles,
    setLanguageFilterArticles,
    setAcknowledgmentFilterArticles,
    setFundingFilterArticles,
    setSinglePageArticles,
    setOffset,
    setLimit,
    setJournalsVolumeFilter,
    setJournalsPublisherFilter,
    setJournalsDocTypeFilter,
    setJournalsCategoriesFilter,
    setSearchJournalsData,
    setSingleJournalNameById,
    setSingleJournalCountById,
    setSingleJournalArticlesById,
    setSearchORGData,
    setOrgVolumeFilter,
    setOrgCategoriesFilter,
    setOrgSinglePageName,
    setOrgSinglePageCount,
    setOrgSingleArticlesById,
    setSearchDataCitedWork,
    setCitedWorkDocTypeFilter,
    setCitedWorkVolumeFilter,
    setSinglePageOffset
} = globalSlice.actions;
export const globalSelector = ((state) => state.global);

export default globalSlice.reducer;