import React from 'react';
import {Link} from "react-router-dom";

const Journals = ({searchResultJournals, handleCheckboxChange, checkedValues}) => {
    return searchResultJournals?.length ? searchResultJournals.map((item, index) => {
        return (
            <article className='info_item' key={index}>
                <label className='custom-input-checkbox'>
                    <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(item.id)}
                        checked={checkedValues.includes(Number(item.id))}
                    />
                    <span className="checkmark"></span></label>
                <span className='info_number'>{index + 1}</span>
                <h3 className='info_title'>
                    <Link to={`/journal/${item.id}`}>{item.name}</Link>
                </h3>
                <span className="line"></span>
                <div className='info_block'>
                    <ul className="info_list mb-0">
                        <li className="info_list--item"><span className="info_label">Journal ID: </span><span
                            className="info_value">{item.j_id}</span></li>
                        <li className="info_list--item"><span className="info_label">Number of Documents: </span><span
                            className="info_value">{item.articles_count}</span></li>
                    </ul>
                </div>
            </article>
        )
    }) : ""
};

export default Journals;