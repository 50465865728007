import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {
    detectSearchTypeUrl,
    dispatch,
    orderByCollection,
    query,
    searchByCollection,
    translation
} from "../../helpers";
import Content from "./components/Content";
import SearchForm from "./components/SearchForm";
import FilterForm from "./components/FilterForm/index";
import {globalSelector, searchTypeAction, setLoader} from "../../redux/slices/global/globalSlice";

const SearchPage = () => {
    const {searchResultsAuthor, loading, searchResultsArticle, searchResultJournals, searchResultOrganizations, searchResultCitedWork} = useSelector(globalSelector);
    const searchObj = JSON.parse(localStorage.getItem("searchObj"));
    const NoSearchObj = !Object.keys(searchObj);
    const [checkedValues, setCheckedValues] = useState([]);
    const history = useHistory();
    const {search} = useLocation();

    if (!Object.keys(query()).length || NoSearchObj) {
        history.push("/");
    }

    const updateURLQuery = (offset, limit) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set('offset', offset.toString());
        searchParams.set('limit', limit.toString());
        history.push({search: searchParams.toString()});
    };

    const detectTotal = () => {
        switch (detectSearchTypeUrl(query())) {
            case "Documents":
                return searchResultsArticle?.total;
            case "Authors":
                return searchResultsAuthor?.total;
            case "Journals":
                return searchResultJournals?.total;
            case "Organizations":
                return searchResultOrganizations?.total;
            case "Cited References":
                return searchResultCitedWork?.total;
            case "ids":
                return searchResultsArticle?.total;
            default:
                return null
        }
    };

    const detectResults = () => {
        switch (detectSearchTypeUrl(query())) {
        case "Documents":
            return searchResultsArticle?.results?.length;
        case "Authors":
            return searchResultsAuthor?.results?.length;
        case "Journals":
            return searchResultJournals?.results?.length;
        case "Organizations":
            return searchResultOrganizations?.results?.length;
        case "Cited References":
            return searchResultCitedWork?.results?.length;
        case "ids":
            return searchResultsArticle?.results?.length;
        default:
            return null
    }
};

    const urlParams = new URLSearchParams(search);
    const idsString = urlParams.get('ids');
    const idsArray = idsString?.split(',').map(Number);

    useEffect(() => {
        if(idsArray?.length){
            dispatch(setLoader(true));
            dispatch(searchTypeAction(detectSearchTypeUrl({ids: idsArray})));
            updateURLQuery(0, 10);
            history.push({
                pathname: '/search',
                search: `${search}`,
            });
            searchByCollection(
                "ids",
                query().searchType,
                {...query(), ids: idsArray},
                 "",
                [],
                orderByCollection({ids: idsArray}),
                Number(query().offset), Number(query().limit)
            );
            localStorage.setItem("searchObj", JSON.stringify({
                type: query().searchType,
                collections: detectSearchTypeUrl({ids: idsArray}),
                dateRange : "",
                inputsData: {...query(), ids: idsArray},
                addedRows: []
            }));
        }
    }, [history.action]);

    return (
        <main className='search_container'>
            <section className='container'>
                <h1 className='search_title'>{detectTotal() || 0} {translation("Result")} {query().searchType} {translation("for")} {query().document_name || Object.values(query()).find(value => value !== '') || ""}</h1>
                <SearchForm/>
                <div className="form-row search-row">
                    <div className="col filter_form-col">
                        <FilterForm setCheckedValues={setCheckedValues}/>
                    </div>
                    <div className="col content_form-col">
                        <div className="content-form">
                            {
                                !detectResults() && !loading ?
                                    <p className="no-data">{translation("No data")}</p> :
                                    <Content checkedValues={checkedValues} setCheckedValues={setCheckedValues}/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default SearchPage;