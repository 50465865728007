import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory, useParams} from 'react-router-dom';
import ArrowDownSVG from "../../../assets/images/arrowDown.svg";
import ContentCompany from '../components/Organization/ContentCompany';
import {exportAsCsvSinglePage, orgSinglePageApi, translation} from "../../../helpers";
import OrganizationsCitations from "../components/Citations/Organizations";
import {ReactComponent as ArrowSVG} from "../../../assets/images/icons/arrow.svg";
import ArticlesSimilar from "../components/Authors/Articles";
import {globalSelector} from "../../../redux/slices/global/globalSlice";
import Pagination from "../components/SinglePagePagination";

const ItemCompany = () => {
    const [exportResultsArr] = useState(['Export', 'Export_01', 'Export_02', 'Export_03']);
    const [exportResultsOption, setExportResultsOption] = useState(exportResultsArr[0]);
    const [active, setActive] = useState(null);
    const {orgArticlesById, singlePageOffset} = useSelector(globalSelector);
    const {id} = useParams();
    const history = useHistory();

    const ContentFilterItemEnum = {
        ExportResults: 0,
    };

    const targetSection = (id) => {
        if (active === id) {
            setActive(null);
        } else {
            setActive(id);
        }
    };

    const backToSearch = () => {
        if (history.action === "POP") {
            history.push('/')
        } else {
            history.goBack()
        }
    };

    useEffect(() => {
        orgSinglePageApi(id)
    }, [id]);

    return (
        <main className='content_item_container'>
            <section className='content container'>

                <div className='content_filter'>
                    <div className="form-row tb--align-center">
                        <button onClick={backToSearch} className='back-to-link'><ArrowSVG/> Back to the Search</button>
                        <div className='col-auto ml-auto tb--flex flex-gap-10 tb--align-center'>
                            <div className='content_filter-item'>
                                <div className='custom-select'>
                                    <div className="user_container"
                                         onClick={() => targetSection(ContentFilterItemEnum.ExportResults)}>
                                        <p className='dropdown-selected-bold'>{exportResultsOption}</p>
                                        <img src={ArrowDownSVG}
                                             className={active === ContentFilterItemEnum.ExportResults ? 'select-close' : 'select-open'}
                                             alt='arrow-icon'/>
                                    </div>
                                    <div
                                        className={`drop-down-section scroller ${active === ContentFilterItemEnum.ExportResults ? 'section-open' : ''}`}>
                                        <p onClick={() => {
                                            setActive(null);
                                            exportAsCsvSinglePage(Number(id))
                                        }}
                                           className="drop-down-section_button">
                                            {translation(" Export as csv")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="form-row content-row mb-20">
                    <div className="col content-col">
                        <ContentCompany/>
                    </div>

                    <div className="col citations-col">
                        <OrganizationsCitations/>
                    </div>
                </div>

                <div className='info_list'>
                    {
                        Object.keys(orgArticlesById)?.length ?
                            <>
                                <div className='content_filter'>
                                    <div className="form-row tb--align-center">
                                        <div className="col-auto tb--flex flex-gap-20">
                                            <div className='content_publication-text'>
                                                <p>Publications</p>
                                                <p>{orgArticlesById?.total} publication from
                                                    the ASCI</p>
                                            </div>
                                        </div>
                                        {
                                            orgArticlesById?.total ?
                                                <div className='col-auto ml-auto tb--flex flex-gap-20 tb--align-center'>
                                                    <p className='content_showing-text'>
                                                        {
                                                            (singlePageOffset + 1) * 10 > orgArticlesById?.total ? `Showing ${orgArticlesById?.total} of ${orgArticlesById?.total}` :
                                                                `Showing ${(singlePageOffset + 1) * 10} of ${orgArticlesById?.total}`
                                                        }
                                                    </p></div> : ""
                                        }
                                    </div>
                                </div>
                                <div className='info_list mb-20'>
                                    <ArticlesSimilar singlePageAuthorSimilar={orgArticlesById?.results}/>
                                </div>
                                <Pagination id={id} resultsArray={orgArticlesById}/>
                            </> : ""
                    }
                </div>

            </section>
        </main>
    );
};

export default ItemCompany;